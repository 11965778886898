import React, { Component } from 'react';

import { View, Text, StatusBar, StyleSheet, RefreshControl, ActivityIndicator, Image, Platform } from 'react-native';
import { getNavigationOptions } from '../helper/NavigationHelper';

//React native calendar
import moment from 'moment';
import 'moment/locale/pt-br';

import { FontAwesome5, FontAwesome } from '@expo/vector-icons';

import { Calendar, CalendarList, Agenda } from 'react-native-calendars';

import { LocaleConfig } from 'react-native-calendars';

LocaleConfig.locales['br'] = {
	monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
	monthNamesShort: ['Jan.', 'Fev.', 'Mar.', 'Abr.', 'Mai.', 'Jun.', 'Jul.', 'Ago.', 'Set.', 'Out.', 'Nov.', 'Dez.'],
	dayNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'],
	dayNamesShort: ['Dom.', 'Seg.', 'Ter.', 'Qua.', 'Qui.', 'Sex.', 'Sáb.'],
	today: 'Hoje'
};
LocaleConfig.defaultLocale = 'br';
//End React native calendar

import Firestore from '../api/firebase/Firestore';

import SessionHelper from '../helper/SessionHelper';

import Colors from '../constants/Colors'

import DefaultCard from '../components/DefaultCard';
import HeaderSearchBar from '../components/HeaderSearchBar';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { app } from 'firebase';
import LoadingOverlay from '../components/LoadingOverlay';
import { showMessage } from 'react-native-flash-message';
import * as Location from 'expo-location';
import GeographicHelper from '../helper/GeographicHelper';
import { ScrollView } from 'react-native-web';

export default class HomeScreen extends Component {
	state = {
		paginationStartDate: moment().subtract(10, 'month'),
		paginationEndDate: moment().add(4, 'month'),
		technicianId: SessionHelper.getTechnician().id,
		appointments: [],
		info: {
			lastVisible: null,
			allLoaded: false
		},
		quering: false,
		companies: [],
		markedDates: {},
		items: {}
	}

	syncAppointments = async () => {
		if (!this.state.quering) {
			await this.setState({
				items: {},
				appointments: [],
				quering: true
			});

			await this.getAppointments();

			await this.setState({
				quering: false
			});
		} else {
			showMessage({
				icon: 'warning',
				message: "Carregando, aguarde...",
				type: "warning",
			});
		}

	}

	async componentDidMount() {
		const { navigation } = this.props;
		navigation.setParams({
			syncAppointments: this.syncAppointments
		});

		await this.getDocs();

		this.willFocusSubscription = this.props.navigation.addListener(
			'willFocus',
			async () => {
				if (!this.state.quering) {
					await this.syncAppointments();
				}
			}
		);

		await this.getLocation();

		StatusBar.setBarStyle('light-content');
	}

	getLocation = async () => {
		let granted = true;

		await Location.requestForegroundPermissionsAsync();
		let { status } = await Location.getForegroundPermissionsAsync();
		granted = status && status == 'granted';


		if (granted) {
			await GeographicHelper.getCurrentPosition(async (location) => {
				let locationObject = {
					coords: {
						latitude: location.lat,
						longitude: location.lng,
						speed: 0
					}
				};

				SessionHelper.setLocation(location);
				await GeographicHelper.recordTechnicianGeodata([locationObject]);
			});
		}
	}

	getDocs = async () => {
		await this.setState({ quering: true });

		await this.getCompanies();
		await this.getAppointments();

		await this.setState({ quering: false });
	}

	componentWillUnmount() {
		if (this.willFocusSubscription) {
			this.willFocusSubscription.remove();
		}
	}

	static navigationOptions = ({ navigation }) => {
		const { params = {} } = navigation.state;

		return getNavigationOptions(`Minhas Assistências`, <View style={{ flexDirection: 'row' }}>
			<TouchableOpacity onPress={() => { params.syncAppointments() }}>
				<FontAwesome color={'white'} style={{ paddingHorizontal: 10 }} size={18} name={'repeat'} />
			</TouchableOpacity>
		</View>);
	};

	getCompanies = async () => {
		const query = await Firestore.customQuery('company').get();

		const companies = [];

		query.forEach(company => {
			let data = company.data();
			data.id = company.id;

			companies.push(data);
		});

		await this.setState({ companies });
	}

	getCompany(id) {
		let docs = this.state.companies;
		let lookup = {
			name: 'Empresa Desconhecida',
			google_formatted_address: 'Endereço desconhecido',
		};

		docs.forEach(doc => {
			if (id === doc.id) {
				lookup = doc;
			}
		});

		return lookup;
	}

	getPriorityColor(priority) {
		const colors = {
			low: '#84BB1C',
			normal: '#FAA219',
			high: '#D43030'
		};

		return colors[priority];
	}

	getCheckedOut(sat) {
		let output = false;

		if (sat.checkedOutTechnicians) {
			if (sat.checkedOutTechnicians.length > 0) {
				sat.checkedOutTechnicians.forEach(item => {
					if (item.technician_id === this.state.technicianId) {
						output = true;
					}
				})
			}
		}

		return output;
	}

	getAppointments = async () => {
		const query = await Firestore.customQuery('appointment').where('status', '==', 'open').where('start_date', '>=', this.state.paginationStartDate.toDate()).where('start_date', '<=', this.state.paginationEndDate.toDate()).get();

		const appointments = [];

		this.loadItems({ timestamp: moment() });

		query.forEach(doc => {
			let appointment = doc.data();
			appointment.id = doc.id;

			if (appointment.technicians) {
				appointment.technicians.forEach(technician => {
					if (technician.id_technician === this.state.technicianId) {
						const strTime = moment(technician.start.toDate()).format('YYYY-MM-DD');

						if (!this.state.items[strTime]) this.state.items[strTime] = [];

						if (this.state.items[strTime]) {
							this.state.items[strTime].push({
								name: appointment.title,
								height: 'auto',
								color: this.getPriorityColor(appointment.priority),
								company: this.getCompany(appointment.company_id),
								checkedOut: this.getCheckedOut(appointment),
								technicianAppointment: technician,
								sat: appointment
							});
						}

						if (!this.state.markedDates[strTime]) this.state.markedDates[strTime] = {};

						if (this.state.markedDates[strTime]) {
							let startingDay = true;
							let endingDay = false;
							let diff = moment(technician.end.toDate()).diff(moment(technician.start.toDate()), 'days');

							if (diff >= 1) {
								if (diff > 1) {
									for (let i = 1; i < diff + 1; i++) {
										const strTimeBetween = moment(technician.start.toDate()).add(i, 'day').format('YYYY-MM-DD');
										if (!this.state.markedDates[strTimeBetween]) this.state.markedDates[strTimeBetween] = { startingDay: false, endingDay: false, color: this.getPriorityColor(appointment.priority), textColor: '#fff', dataPointer: strTime };
									}
								}

								const strTimeEnd = moment(technician.end.toDate()).format('YYYY-MM-DD');

								if (!this.state.markedDates[strTimeEnd]) this.state.markedDates[strTimeEnd] = {};

								if (this.state.markedDates[strTimeEnd]) {
									this.state.markedDates[strTimeEnd] = { startingDay: false, endingDay: true, color: this.getPriorityColor(appointment.priority), textColor: '#fff', dataPointer: strTime };
								}
							} else {
								endingDay = true;
							}

							this.state.markedDates[strTime] = { startingDay, endingDay, color: this.getPriorityColor(appointment.priority), textColor: '#fff', dataPointer: strTime };
						}

						appointments.push(appointment);
					}
				});
			}

		});

		this.selectDate(moment().format('YYYY-MM-DD'));
		this.setState({ appointments });
	}

	loadItems(date) {
		for (let i = -15; i < 85; i++) {
			const time = date.timestamp + i * 24 * 60 * 60 * 1000;
			const strTime = this.timeToString(time);
			if (!this.state.items[strTime]) {
				this.state.items[strTime] = [];
			}
		}
	}

	timeToString(time) {
		const date = new Date(time);
		return date.toISOString().split('T')[0];
	}

	renderItem(item) {
		const startDate = item.technicianAppointment.start ? moment(item.technicianAppointment.start.toDate()).format('DD/MM HH:mm') : '?';
		const endDate = item.technicianAppointment.end ? moment(item.technicianAppointment.end.toDate()).format('DD/MM HH:mm') : '?';

		const technicianCount = item.sat.technicians ? item.sat.technicians.length : 0;

		return (
			<TouchableOpacity onPress={() => { this.props.navigation.navigate('Sat', { sat: item.sat, technicianAppointment: item.technicianAppointment }) }} style={{ height: 'auto' }}>
				<View style={[styles.item, { height: item.height, backgroundColor: !item.checkedOut ? item.color : Colors.disabled }]}>
					<View style={{ flexDirection: 'row' }}>
						<FontAwesome5 solid size={42} name={!item.checkedOut ? 'cog' : 'check'} style={{
							color: '#ffff',
							fontSize: 16,
							marginTop: 1,
							marginRight: 8
						}} />
						<Text style={{
							color: '#ffff',
							fontWeight: '800',
							fontSize: 17,
							textDecorationLine: !item.checkedOut ? 'none' : 'line-through'
						}}>{item.name}</Text>
					</View>
					<View style={{ flexDirection: 'row' }}>
						<FontAwesome5 solid size={42} name={'industry'} style={{
							color: '#ffff',
							fontSize: 13,
							marginTop: 11,
							marginRight: 10
						}} />
						<Text style={{
							color: '#ffff',
							fontWeight: '400',
							fontSize: 14,
							marginTop: 10
						}}>{item.company.name}</Text>
					</View>
					<View style={{ flexDirection: 'row' }}>
						<FontAwesome5 solid size={42} name={'map-marker-alt'} style={{
							color: '#ffff',
							fontSize: 13,
							marginTop: 11,
							marginRight: 10
						}} />
						<Text style={{
							color: '#ffff',
							fontWeight: '400',
							fontSize: 14,
							marginTop: 10,
							maxWidth: '80%',
						}}>{item.company.google_formatted_address}</Text>
					</View>
					<View style={{ flexDirection: 'row', width: '100%' }}>
						<View style={{ flexDirection: 'row' }}>
							<FontAwesome5 solid size={42} name={'calendar'} style={{
								color: '#ffff',
								fontSize: 13,
								marginTop: 11,
								marginRight: 10
							}} />
							<Text style={{
								color: '#ffff',
								fontWeight: '400',
								fontSize: 14,
								marginTop: 10
							}}>{startDate} ~ {endDate}</Text>
						</View>
						<View style={{ flexDirection: 'row', position: 'absolute', right: 10 }}>
							<FontAwesome5 solid size={42} name={'user'} style={{
								color: '#ffff',
								fontSize: 13,
								marginTop: 11,
								marginRight: 10
							}} />
							<Text style={{
								color: '#ffff',
								fontWeight: '400',
								fontSize: 14,
								marginTop: 10
							}}>{technicianCount}</Text>
						</View>
					</View>
				</View>
			</TouchableOpacity>
		);
	}

	renderEmptyDate() {
		return null;
	}

	async selectDate(date) {
		let markedDates = this.state.markedDates;

		Object.keys(markedDates).forEach(day => {
			if (markedDates[day].marked === true) {
				markedDates[day].marked = false;
			}
		});

		if (!markedDates[date]) {
			markedDates[date] = { marked: true };
		} else {
			markedDates[date].marked = true;
		}

		let selectedData = {};

		Object.keys(markedDates).forEach(day => {
			if (markedDates[day].marked === true && markedDates[day].dataPointer) {
				if (this.state.items[markedDates[day].dataPointer]) {
					selectedData = this.state.items[markedDates[day].dataPointer][0];
				}
			}
		});

		this.setState({ markedDates, selectedData });
	}

	renderWebCalendar() {
		return (
			<View style={{ flexDirection: 'row', height: '100%' }}>
				<CalendarList
					current={moment().format('YYYY-MM-DD')}
					markedDates={this.state.markedDates}
					markingType="period"
					showScrollIndicator={true}
					scrollEnabled={true}
					calendarWidth={400}
					minDate={moment(this.state.paginationStartDate).format('YYYY-MM-DD')}
					maxDate={moment(this.state.paginationEndDate).format('YYYY-MM-DD')}
					pastScrollRange={10}
					futureScrollRange={4}
					onDayPress={(day) => {
						this.selectDate(day.dateString);
					}}
					theme={{
						dotColor: Colors.disabled
					}}
				/>
				<View style={{ width: 'calc(100% - 400px)', padding: 10 }}>
					{this.state.selectedData && this.state.selectedData.name ? this.renderItem(this.state.selectedData) : <View style={styles.notFoundContainer}>
						<Image
							style={styles.notFoundImage}
							source={require('../assets/images/undraw/empty.png')}
						/>
						<Text style={styles.notFoundText}>Nenhum evento na data selecionada.</Text>
					</View>}
				</View>
			</View>
		);
	}

	renderMobileCalendar() {
		return (
			<Agenda
				items={this.state.items}
				selected={new Date()}
				minDate={moment(this.state.paginationStartDate).format('YYYY-MM-DD')}
				maxDate={moment(this.state.paginationEndDate).format('YYYY-MM-DD')}
				pastScrollRange={10}
				futureScrollRange={4}
				loadItemsForMonth={this.loadItems.bind(this)}
				renderItem={this.renderItem.bind(this)}
				renderEmptyDate={this.renderEmptyDate.bind(this)}
				rowHasChanged={(r1, r2) => { return r1.id !== r2.id }}
				theme={{
					todayTextColor: Colors.secondaryTintColor,
					dotColor: Colors.secondaryTintColor,
					selectedDotColor: Colors.secondaryTintColor,
					selectedDayBackgroundColor: Colors.tintColor,
					agendaTodayColor: Colors.secondaryTintColor,
					agendaKnobColor: Colors.tintColor
				}}
			/>
		);
	}

	renderAgenda() {
		return (
			<View style={styles.container}>
				{this.state.quering ? <LoadingOverlay title={'Carregando suas assistências...'} /> :
					Platform.OS !== 'web' ? this.renderMobileCalendar() : this.renderWebCalendar()}
			</View>
		);
	}

	render() {
		return this.renderAgenda();
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor,
	},
	notFoundContainer: {
		flex: 1,
		justifyContent: "center",
		alignSelf: "center",
	},
	notFoundImage: {
		justifyContent: "center",
		alignSelf: "center",
		height: 160,
		width: 168,
	},
	emptyImage: {
		justifyContent: "center",
		alignSelf: "center",
		height: 160,
		width: 220,
	},
	notFoundText: {
		padding: 20,
		fontSize: 16,
		justifyContent: "center",
		textAlign: "center",
		color: 'grey'
	},
	loadingContainer: {
		flex: 1,
		marginTop: '50%',
		justifyContent: "center",
		textAlign: "center",
	},
	loadingText: {
		fontSize: 14,
		justifyContent: "center",
		textAlign: "center",
		color: Colors.text
	},
	item: {
		padding: 10,
		flex: 1,
		borderRadius: 5,
		paddingLeft: 10,
		marginRight: 10,
		marginTop: 5,
	},
	emptyDate: {
		backgroundColor: 'green',
		flex: 1,
		borderRadius: 5,
		padding: 10,
		marginRight: 10,
		marginTop: 5,
		height: 20,
	}
});