import React from 'react';
import { TouchableOpacity, StyleSheet, Text, ActivityIndicator } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";

import Colors from '../constants/Colors';

export default class DefaultButton extends React.Component {

    getButtonStyle() {
        return {
            padding: 15,
            width: '100%',
            borderRadius: 5,
            backgroundColor: this.props.color ? this.props.color : Colors.tintColor,
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            marginVertical: 5,
        }
    }

    getTextStyle() {
        return {
            fontSize: 17,
            alignSelf: 'center',
            color: this.props.textColor ? this.props.textColor : 'white',
            fontWeight: 'bold'
        }
    }

    render() {

        return (
            <TouchableOpacity style={this.getButtonStyle()} onPress={
                () => {
                    if (!this.props.loading) {
                        this.props.onPress()
                    } else {
                        showMessage({
                            icon: 'warning',
                            message: "Realizando ação. Aguarde...",
                            type: "warning",
                        });
                    }
                }}>
                {this.props.icon ? <FontAwesome5 solid={this.props.solidIcon ? true : false} style={{ marginRight: 10 }} size={17} name={this.props.icon} color={this.props.textColor ? this.props.textColor : 'white'} /> : null}
                {this.props.loading ? <ActivityIndicator color={'#ffff'} /> : <Text style={this.getTextStyle()}>{this.props.title}</Text>}
            </TouchableOpacity>
        );
    }
}