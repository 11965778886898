import React from 'react';
import { Modal, View, StyleSheet, Text, ActivityIndicator, TouchableWithoutFeedback, Platform } from 'react-native';
import { BlurView } from 'expo-blur';
import Colors from '../constants/Colors';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';

export default class DefaultModal extends React.Component {

	render() {

		return (
			<Modal transparent={true} visible={this.props.visible} animationType={this.props.animation}>
				<BlurView tint={'light'} intensity={50} style={{ flex: 1 }}>
					<View style={styles.modal}>

						<TouchableWithoutFeedback style={styles.closeWrapper} onPress={() => { this.props.onClose() }}>
							<View style={styles.closeBtn} >
								<FontAwesome5 style={{ alignSelf: 'center' }} name={'times'} color={Colors.backgroundColor} size={22} />
							</View>
						</TouchableWithoutFeedback>

						{Platform.OS === 'ios' ?
							<KeyboardAwareScrollView style={{ height: '100%' }}>

								{this.props.content}

							</KeyboardAwareScrollView>
							:
							<KeyboardAwareScrollView
								enableAutomaticScroll
								extraScrollHeight={60}
								enableOnAndroid={true}
								extraHeight={Platform.select({ android: 120 })}
							>

								{this.props.content}

							</KeyboardAwareScrollView>
						}

					</View>
				</BlurView>
			</Modal>
		);
	}
}

const styles = StyleSheet.create({
	modal: {
		// marginVertical: 30,
		marginHorizontal: 12,
		height: 'auto',
		minHeight: 400,
		backgroundColor: 'transparent',
		borderRadius: 5,
		marginTop: '10%',
		marginBottom: 15,
		borderColor: 'transparent'
	},
	closeWrapper: {
		flexDirection: 'row-reverse',
		marginHorizontal: 12,
		width: '100%'
	},
	closeBtn: {
		backgroundColor: Colors.secondaryTintColor,
		height: 42,
		width: 42,
		justifyContent: 'center',
		borderRadius: 32,
	}
});