import * as React from 'react';
import { StyleSheet, Text, View, Image, StatusBar } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import Auth from '../api/firebase/Auth';
import Colors from '../constants/Colors';
import { getNavigationOptions, toggleStatusBarStyle } from '../helper/NavigationHelper';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import Badge from '../components/Badge';

export default class BadgeScreen extends React.Component {
	state = {
		technician: {}
	}

	async componentDidMount() {
		await this.getTechnician();

		StatusBar.setBarStyle('light-content');
	}

	async getTechnician() {
		const query = await Firestore.customQuery('technician').where('user_id', '==', SessionHelper.getFirebaseAuth().uid).limit(1).get();

		this.setState({ technician: query.docs[0].data() });
	}

	render() {
		return (
			<View style={styles.container}>
				<Badge photo={this.state.technician.photo} name={this.state.technician.name} cpf={this.state.technician.cpf} />
			</View>
		);
	}
}

BadgeScreen.navigationOptions = getNavigationOptions('Crachá Digital');

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor,
		maxHeight: '90%'
	}
});
