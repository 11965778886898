import * as React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ActivityIndicator, Alert, Platform } from 'react-native';
import { ScrollView, FlatList } from 'react-native-gesture-handler';

import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";
import moment from 'moment';
import 'moment/locale/pt-br';

import { getNavigationOptions, toggleStatusBarStyle } from '../helper/NavigationHelper';
import SessionHelper from '../helper/SessionHelper';
import CurrencyHelper from '../helper/CurrencyHelper';
import SATHelper from '../helper/SATHelper';

import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';

import Colors from '../constants/Colors';
import ReviewedPartsNames from '../constants/ReviewedPartsNames';

import LoadingOverlay from '../components/LoadingOverlay';
import DefaultButton from '../components/DefaultButton';
import TextArea from '../components/TextArea';


export default class FinishSatScreen extends React.Component {
	state = {
		loading: true,
		loadingCard: false,
		technicianId: SessionHelper.getTechnician().id,
		userId: SessionHelper.getFirebaseAuth().uid,
		technicians: [],
		sat: null,
		loadingSignature: false,
		technicianDescription: ''
	}

	async componentDidMount() {
		const { navigation } = this.props;
		navigation.setParams({
			finishSat: this.finishSat
		});

		await this.setNavigationData(this.props.navigation.state.params);
		await this.getTechnicians();

		this.willFocusSubscription = this.props.navigation.addListener(
			'willFocus',
			() => {
				this.syncSat();
			}
		);

		this.setState({ loading: false });
	}

	componentWillUnmount() {
		this.willFocusSubscription.remove();
	}

	getTechnicians = async () => {
		const query = await Firestore.getCollection('technician');

		const technicians = [];

		query.forEach(technician => {
			let currentTech = technician.data();
			currentTech.id = technician.id;

			technicians.push(currentTech);
		});

		this.setState({ technicians });
	}

	getTechnicianName = (technician_id) => {
		let output;

		this.state.technicians.forEach(current => {
			if (technician_id === this.state.technicianId) {
				output = {
					name: 'Você',
					you: true
				}
			} else {
				output = {
					name: current.name,
					you: false
				}
			}
		});

		return output;
	}

	setNavigationData = async (params) => {
		await this.setState({ sat: params.sat });
	}

	finishSat = async () => {
		Alert.alert(
			'Fazer Checkout e Finalizar?',
			`Após finalizar não será mais possível editar esta SAT. Clique em "Checkout" para prosseguir e "Cancelar" para continuar editando.`,
			[
				{
					text: 'Cancelar',
					onPress: () => { },
					style: 'cancel',
				},
				{ text: 'Checkout', onPress: () => this.doFinishSat() },
			],
			{ cancelable: true },
		);
	}

	doFinishSat = async () => {
		let signature = false;
		let description = false;

		if (this.state.sat.attachments) {
			this.state.sat.attachments.forEach(current => {
				if (current.user_id === this.state.userId && current.type === 'signature') {
					signature = true;
				}
			});
		}

		if (this.state.technicianDescription != '' && this.state.technicianDescription.length > 9) {
			description = true;
		}


		if (!signature) {
			showMessage({ icon: 'warning', message: "Assinatura digital é Obrigátória", type: "warning" });
		}

		if (!description) {
			showMessage({ icon: 'warning', message: "Descrição é Obrigátória e deve conter pelo menos 10 caracteres", type: "warning" });
		}

		if (signature && description) {
			this.setState({ loading: true });

			const checkedOutTechnician = {
				user_id: this.state.userId,
				technician_id: this.state.technicianId,
				description: this.state.technicianDescription
			}

			if (!this.state.sat.checkedOutTechnicians || this.state.sat.checkedOutTechnicians < 1) {
				this.state.sat.checkedOutTechnicians = [checkedOutTechnician];
			} else {
				this.state.sat.checkedOutTechnicians.push(checkedOutTechnician);
			}

			try {
				await Firestore.update({ checkedOutTechnicians: this.state.sat.checkedOutTechnicians },
					'appointment', this.state.sat.id)

				await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico fez checkout de SAT e descreveu como: ${this.state.technicianDescription}`, new Date());

				showMessage({
					icon: 'success',
					message: 'Bom trabalho! Checkout e finalização realizados com sucesso!',
					type: "success",
				});

				this.props.navigation.navigate('Home');

				this.setState({ loading: false });
			} catch (e) {
				showMessage({
					icon: 'danger',
					message: 'Desculpe, erro ao realizar checkout e finalizar.',
					type: "danger",
				});

				this.setState({ loading: false });
			}

		}
	}

	syncSat = async () => {
		const query = await Firestore.getDoc('appointment', this.state.sat.id);

		let sat = query.data();
		sat.id = query.id;

		this.setState({ sat });
	}

	removePart = async (permition, item, arrayPosition) => {
		if (permition) {
			this.setState({ loadingCard: true });

			showMessage({ icon: '', message: "Removendo peça", type: "" });

			try {
				await this.syncSat();

				this.state.sat.replacedParts.splice(arrayPosition, 1);

				await Firestore.update({ replacedParts: this.state.sat.replacedParts },
					'appointment', this.state.sat.id)

				await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico removeu peça ${item.name}`, new Date());

				showMessage({ icon: 'success', message: "Removido com sucesso", type: "success" });

				await this.syncSat();

				this.setState({ loadingCard: false });
			} catch (e) {
				showMessage({ icon: 'danger', message: "Ocorreu um erro ao remover", type: "danger" });
				this.setState({ loadingCard: false });
			}

		} else {
			showMessage({ icon: 'danger', message: "Você não pode remover a peça de outro técnico", type: "danger" });
		}
	}

	static navigationOptions = ({ navigation }) => {
		const { params = {} } = navigation.state;

		return getNavigationOptions(`Finalizar SAT ${navigation.state.params.sat.title.replace('Assistência ', '')}`,
			<View style={{ flexDirection: 'row' }}>
				<TouchableOpacity onPress={() => { params.finishSat() }}>
					<FontAwesome5 color={'white'} style={{ paddingHorizontal: 10 }} size={22} name={'check'} />
				</TouchableOpacity>
			</View>
		);
	};

	renderHeader(icon, title) {
		return (
			<View style={styles.cardHeader}>
				<FontAwesome5 style={styles.cardHeaderIcon} light name={icon} />
				<Text style={styles.cardHeaderText}>{title}</Text>
			</View>
		);
	}

	renderDescriptionCard = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('file-alt', 'Descrição da Assistência Técnica')}
				<View style={[styles.card, { paddingHorizontal: 15 }]}>
					<TextArea numberOfLines={15} onChangeText={(value) => {
						this.setState({ technicianDescription: value });
					}
					}
						placeholder={'Descreva aqui suas observações sobre a assitência... (Obrigatório)'}
						style={{ height: 85, width: '100%', textAlignVertical: 'top' }}
					/>
				</View>
			</View>
		);
	}

	renderReplacedPartsCard = () => {
		let content = (
			<ScrollView style={{ width: '100%', paddingHorizontal: 5, marginBottom: 76 }}>
				{this.state.sat.replacedParts ? this.state.sat.replacedParts.reverse().map((item, key) => this.renderReplacedPartRow(item, key)) : null}
			</ScrollView>
		)

		if (this.state.loadingCard) {
			content = (
				<View style={{ width: '100%' }}>
					<ActivityIndicator size={'large'} />
				</View>
			)
		}

		if (!this.state.sat.replacedParts || this.state.sat.replacedParts.length < 1) {
			content = (
				<View style={{ justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 20, top: -25 }}>
					<View style={[styles.imageWrapper, { height: 86, width: 86 }]}>
						<FontAwesome5 light size={42} name={'cog'} color={'#fafafa'} />
					</View>

					<View style={{ maxWidth: '80%', justifyContent: 'center', paddingLeft: 15 }}>
						<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 16, paddingVertical: 5 }}>Nenhum registro de peça substitíuda nessa SAT ainda, clique em "Adicionar Peça Substitíuda" para adicionar!</Text>
					</View>
				</View>
			)
		}

		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('cog', 'Peças Substituídas')}

				<View style={[styles.card, {
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: 'center',
					height: 360,
					paddingVertical: 0
				}]}>
					{content}

					<View style={{ paddingHorizontal: 10, flexDirection: 'row', position: 'absolute', bottom: 10 }}>
						<DefaultButton onPress={() => { this.props.navigation.navigate('AddReplacedParts', { sat: this.state.sat }) }} title={'Adicionar Peça Substitíuda'} icon={'plus'} />
					</View>
				</View>
			</View>
		)
	}

	renderReplacedPartRow = (item, key) => {
		const technician = this.getTechnicianName(item.technician_id);

		return (
			<View key={`${item.name}${item.date}`} style={{ borderBottomWidth: .5, borderColor: Colors.contrastColor, width: '100%', padding: 10, flexDirection: 'row' }}>
				<View style={[styles.imageWrapper, { height: 32, width: 32, marginTop: 18 }]}>
					<FontAwesome5 light style={{ marginLeft: 1 }} size={18} name={'cog'} color={'#fafafa'} />
				</View>
				<View style={{ maxWidth: '80%', justifyContent: 'center', paddingLeft: 15, flexDirection: 'column' }}>
					<Text style={{ fontWeight: '600', color: Colors.text, fontSize: 18, paddingVertical: 3 }}>{item.name} <Text style={{ fontWeight: '400', color: Colors.contrastColor, fontSize: 14 }}>({item.code})</Text> <Text style={{ fontWeight: '600', color: Colors.contrastColor, fontSize: 18 }}>• {item.quantity}</Text></Text>
					<Text style={{ fontWeight: '500', color: Colors.text, fontSize: 14, paddingVertical: 2 }}>{technician.name}</Text>
					<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 14, paddingVertical: 2 }}>{moment(item.date.toDate()).calendar()}</Text>
				</View>
				<TouchableOpacity onPress={() => { this.removePart(technician.you, item, key) }} style={[styles.imageWrapper, { height: 32, width: 32, position: 'absolute', right: 15, top: 18, backgroundColor: technician.you ? Colors.secondaryTintColor : Colors.disabled }]}>
					<FontAwesome5 light style={{ marginLeft: 1 }} size={18} name={'trash'} color={'#fafafa'} />
				</TouchableOpacity>
			</View>
		);
	}

	renderReviewedPartsCard = () => {
		let content = (
			<FlatList
				data={this.state.sat.reviewedParts ? Object.keys(this.state.sat.reviewedParts).sort() : null}
				numColumns={3}
				renderItem={(item) => this.renderReviewedPartRow(item)}
				style={{ width: '100%', padding: 5, marginBottom: 76 }}
				showsVerticalScrollIndicator={false}
			>
			</FlatList>
		)

		if (this.state.loadingCard) {
			content = (
				<View style={{ width: '100%' }}>
					<ActivityIndicator size={'large'} />
				</View>
			)
		}

		if (!this.state.sat.reviewedParts || this.state.sat.reviewedParts.length < 1) {
			content = (
				<View style={{ justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 20, top: -25 }}>
					<View style={[styles.imageWrapper, { height: 86, width: 86 }]}>
						<FontAwesome5 light size={42} name={'wrench'} color={'#fafafa'} />
					</View>

					<View style={{ maxWidth: '80%', justifyContent: 'center', paddingLeft: 15 }}>
						<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 16, paddingVertical: 5 }}>Nenhuma peça revisada nesta SAT ainda, Clique em "Informar Revisadas" para informar peças revisadas.</Text>
					</View>
				</View>
			)
		}

		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('wrench', 'Peças Revisadas')}

				<View style={[styles.card, {
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: 'center',
					height: 540,
					paddingVertical: 0
				}]}>
					{content}

					<View style={{ paddingHorizontal: 10, flexDirection: 'row', position: 'absolute', bottom: 10 }}>
						<DefaultButton onPress={() => { this.props.navigation.navigate('AddReviewedParts', { sat: this.state.sat }) }} title={'Informar Revisadas'} icon={'plus'} />
					</View>
				</View>
			</View>
		)
	}

	renderReviewedPartRow = (item) => {
		const reviewed = this.state.sat.reviewedParts[item.item];
		const name = ReviewedPartsNames[item.item];

		return (
			<View style={{
				alignItems: "center",
				justifyContent: "center",
				backgroundColor: reviewed ? Colors.contrastColor : Colors.backgroundColor,
				flexGrow: 1,
				flexBasis: 0,
				borderRadius: 5,
				margin: 4,
				padding: 15
			}}>
				<Text style={{
					color: Colors.text,
					fontWeight: '400',
					fontSize: 14
				}}>{name}</Text>
			</View>
		);
	}

	renderSignatureCard = () => {
		let signatureExists = false;
		let signature = false;
		let signatureInfo = false;

		let noSignature = (
			<View style={{ justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 20 }}>
				<View style={[styles.imageWrapper, { height: 86, width: 86 }]}>
					<FontAwesome5 light size={42} name={'pen-fancy'} color={'#fafafa'} />
				</View>

				<View style={{ maxWidth: '80%', justifyContent: 'center', paddingLeft: 15 }}>
					<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 16, paddingVertical: 5 }}>Assinatura ainda não coletada, clique em "Obter assinatura" para coletar, você pode editá-la mais tarde também, antes de finalizar, caso precise! :)</Text>
				</View>
			</View>
		);

		if (this.state.sat.attachments) {
			this.state.sat.attachments.forEach(current => {
				if (current.user_id === this.state.userId && current.type === 'signature') {
					signatureExists = true;
					signature = <Image style={{ width: '100%', height: 250, aspectRatio: 0.6 }} source={{ uri: current.link }} />
					signatureInfo = current.signatureInfo;
				}
			});
		}

		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('pen-fancy', 'Assinatura Digital')}
				<View style={[styles.card, { height: signatureExists && signature ? 450 : 320 }]}>
					<View style={{ width: '100%' }}>
						{signatureExists && signature ? Object.keys(signatureInfo).reverse().map(current => (signatureInfo[current] ? <Text key={current} style={styles.signatureInfo}>{signatureInfo[current]}</Text> : null)) : null}
						{signatureExists && signature ? <View style={{ height: 1, width: '100%', backgroundColor: Colors.contrastColor, marginTop: 15, marginBottom: 5 }}></View> : null}
					</View>
					<View style={{ height: 245, width: '100%', justifyContent: 'center', alignItems: 'center' }}>
						{signatureExists && signature ? signature : noSignature}
					</View>
					<View style={{ paddingHorizontal: 10, flexDirection: 'row', position: 'absolute', bottom: 10 }}>
						{Platform.OS == 'web' ?
							<View style={{ justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 20, width: '100%' }}>
								<View style={[styles.imageWrapper, { height: 32, width: 32 }]}>
									<FontAwesome5 light size={18} name={'info'} color={'#fafafa'} />
								</View>

								<View style={{ justifyContent: 'center', paddingLeft: 15 }}>
									<Text style={{ fontWeight: 'bold', color: Colors.text, fontSize: 16, paddingVertical: 5, marginTop: 5 }}>Para coletar ou recoletar a assinatura use o aplicativo do técnico em seu celular.</Text>
								</View>
							</View>
							:
							<DefaultButton onPress={() => { this.props.navigation.navigate('SignatureInfo', { sat: this.state.sat }) }} title={signatureExists ? 'Recoletar Assinatura e Dados' : 'Obter Assinatura e Dados'} icon={signatureExists ? 'sync' : 'pen-fancy'} />}

					</View>
				</View>
			</View>
		);
	}

	renderContent = () => {
		return (
			<View style={styles.container}>
				<ScrollView>
					{this.renderDescriptionCard()}
					{this.renderSignatureCard()}
					{this.renderReplacedPartsCard()}
					{this.renderReviewedPartsCard()}
				</ScrollView>
			</View>

		);
	}

	render() {
		return this.state.loading ? <LoadingOverlay title={'Carregando SAT...'} /> : this.renderContent();
	}

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	cardWrapper: {
		padding: 10
	},
	card: {
		width: '100%',
		paddingVertical: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 130,
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.contrastColor,
		marginTop: 10,
		height: 68,
		width: 68,
		borderRadius: 68,
	},
	image: {
		aspectRatio: 1 / 1,
		height: 64,
		width: 64,
		borderRadius: 64
	},
	technicianBubbleText: {
		textAlign: 'center',
		paddingVertical: 8,
		fontSize: 12,
		color: Colors.text,
		fontWeight: "500",
		paddingHorizontal: 3
	},
	cardHeader: {
		flexDirection: 'row',
		width: '100%',
		marginBottom: 5,
		padding: 5,
		justifyContent: 'flex-start',
		textAlignVertical: 'center'
	},
	cardHeaderText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: Colors.tintColor,
		marginLeft: 5
	},
	cardHeaderIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginRight: 5
	},
	floatingButton: {
		position: 'absolute',
		bottom: 10,
		justifyContent: 'center',
		alignSelf: 'center',
		width: '90%'
	},
	signatureInfo: {
		paddingHorizontal: 10,
		paddingVertical: 2,
		fontWeight: '400',
		fontSize: 15,
		color: Colors.text
	}
});
