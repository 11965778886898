export default class SessionHelper {

	constructor() {
		this.firebaseAuth;
		this.data;
		this.technician;
		this.location = {
			lat: 0,
			lng: 0
		};
	}
	static setLocation(location) {
		this.location = location;
	}

	static getLocation() {
		return this.location;
	}

	static setFirebaseAuth(auth) {
		this.firebaseAuth = auth;
	}

	static getFirebaseAuth() {
		return this.firebaseAuth;
	}

	static setData(data) {
		this.data = data;
	}

	static getData() {
		return this.data;
	}

	static setTechnician(technician) {
		this.technician = technician;
	}

	static getTechnician() {
		return this.technician;
	}
}