import React from 'react';
import { View, TextInput, StyleSheet } from 'react-native';

import Colors from '../constants/Colors';

export default class TextArea extends React.Component {

	state = {
		value: this.props.value ? this.props.value : '',
		isFocused: false,
	}

	handleFocus = event => {
		this.setState({ isFocused: true });

		if (this.props.onFocus) {
			this.props.onFocus(event);
		}
	}

	handleBlur = event => {

		this.setState({ isFocused: false });

		if (this.props.onBlur) {
			this.props.onBlur(event);
		}
	}

	handleTextChange = async event => {
		await this.setState({ value: event })

		this.props.onChangeText(event);
	}

	render() {

		return (
			<View style={{ marginTop: 15, marginVertical: 25, padding: 5, borderBottomWidth: 2, borderColor: this.state.isFocused ? Colors.tintColor : '#D3D3D3' }} >
				<TextInput
					style={this.props.style ? this.props.style : styles.textArea}
					underlineColorAndroid="transparent"
					placeholder={this.props.placeholder}
					placeholderTextColor="grey"
					numberOfLines={this.props.numberOfLines ? this.props.numberOfLines : 10}
					multiline={true}
					autoCapitalize={"sentences"}
                    autoCorrect={true}
					onFocus={this.handleFocus}
					onBlur={this.handleBlur}
					value={this.props.value}
					onChangeText={this.handleTextChange}
					ref={(input) => { this.inputRef = input; }}
				/>
			</View>
		);
	}
};

const styles = StyleSheet.create({
	textArea: {
		minHeight: 100,
		maxHeight: 200,
		textAlignVertical: 'top'
	}
})
