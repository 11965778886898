const tintColor = '#289c58';
const secondaryTintColor = '#d74d4e';
const contrastColor = '#7DC383';

export default {
  tintColor,
  secondaryTintColor,
  contrastColor,
  title: '#46484a',
  text: '#404040',
  disabled: '#999999',
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  backgroundColor: 'rgb(240, 240, 240)',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
};
