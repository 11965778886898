import Firestore from '../api/firebase/Firestore';
import moment from 'moment';

export default class SATHelper {

    static async historyIteration(id_sat, id_user = '', message = '', date = new Date(), file = '') {

        try {

            let sat = await Firestore.customQuery('appointment').doc(id_sat).get();

            if (sat.exists) {
    
                let history = sat.data().history;
    
                if (!history) history = [];
    
                history.push({ id_user: id_user, message: message, date: date, file: file });
    
                return await Firestore.update({ history: history }, 'appointment', id_sat);

            } else {

                throw new Error('SAT não encontrado');
            }

        } catch (error) {

            return error;
        }
    }

    static async reopenSAT(id_sat, status = 'open', id_user = '') {

        try {

            if (!id_sat) throw new Error('SAT não informado');

            let query = await Firestore.customQuery('appointment').doc(id_sat).get();

            if (query.exists) {
    
                await Firestore.update({ status: status, start_date: new Date() }, 'appointment', id_sat);
                await this.historyIteration(id_sat, id_user, 'SAT reaberta');

                return true;

            } else {

                throw new Error('SAT não encontrado');
            }

        } catch (error) {

            return error;
        }
    }
}