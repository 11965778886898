import * as React from 'react';
import { FontAwesome5 } from '@expo/vector-icons';

import Colors from '../constants/Colors';

export default function TabBarIcon(props) {
  return (
    <FontAwesome5
      solid={props.solid}
      name={props.name}
      size={26}
      solid={props.solid ?  true : false}
      color={props.focused ? Colors.tabIconSelected : Colors.tabIconDefault}
    />
  );
}
