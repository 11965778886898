import { Platform } from 'react-native';

export default class CurrencyHelper {

    static centsToMoney(cents) {

        let money = Math.abs(cents / 100);

        if(Platform.OS === 'ios') {
            return money.toLocaleString("pt-BR", {style:"currency", currency:"BRL"});
        } else {
            return "R$ " + money.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
        }
    }

    static moneyToCents(money) {
        let cents = Math.abs(money * 100);

        return cents;
    }
}