import React from 'react';
import { View, StyleSheet, Text, Image, ActivityIndicator } from 'react-native';
import { BlurView } from 'expo-blur';
import Colors from '../constants/Colors';

export default class LoadingOverlay extends React.Component {

	render() {

		return (
			<BlurView tint={'light'} intensity={this.props.intesity ? this.props.intesity : 50} style={styles.loading}>
				<Image style={styles.gifLoading} source={require('../assets/images/loading.gif')}></Image>
				<Text style={styles.text}>{this.props.title ? this.props.title : 'Aguarde...'}</Text>
			</BlurView>
		);
	}
}

const styles = StyleSheet.create({
	loading: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	text: {
		marginTop: 10,
		color: Colors.tintColor
	},
	gifLoading: {
        width: 96,
        height: 96,
        alignSelf: 'center',
        marginBottom: 10,
        borderRadius: 10
    }
});