import React, { Component } from 'react';

import { TextInput, View, StyleSheet } from 'react-native';
import Colors from '../constants/Colors';
import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { getStatusBarHeight } from 'react-native-status-bar-height';

export default class HeaderSearchBar extends Component {
    state = {
        value: '',
        isFocused: false,
        isTyping: false,
        typingTimeout: 0,
        actionState: 'search'
    }

    handleTextChange = (event) => {
        this.setState({ value: event });

        this.dynamicSearch(event);
    }

    handleFocus = (event) => {
        this.setState({ isFocused: true });

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    handleAction = async (term, clear) => {
        if (term.length > 1) {
            this.setState({ actionState: 'times' })
        }

        if(clear) {
            this.setState({ value: '' });
            this.searchInput.clear();
            this.setState({ actionState: 'search', isFocused: false });
            this.searchInput.blur(false);
        }

        if(this.props.actionButton && clear) {
            this.props.actionButton(clear);
        }
    }

    dynamicSearch = async (text) => {
        const term = text;

        this.handleAction(term, false);

        const minChars = this.props.minChars ? this.props.minChars : 3;

        if (term.length < minChars) {
            return;
        }

        if (this.state.typingTimeout) {
            clearTimeout(this.state.typingTimeout);
         }
     
         const that = this;
         this.setState({
            isTyping: false,
            typingTimeout: setTimeout(async () => {
                 await that.search(term);
             }, 1000)
         });
    }

    search = async (term) => {
        const searchObject = {
            term: term
        }

        this.props.handleSearch(searchObject);
    }

    render() {
        return <View style={styles.header}>
            <View style={styles.inputWrapper}>
                <TextInput
                    ref={input => { this.searchInput = input }}
                    style={styles.input}
                    value={this.props.value}
                    keyboardType={this.props.keyboardType}
                    onChangeText={this.handleTextChange}
                    secureTextEntry={this.props.secureTextEntry}
                    placeholder={this.props.placeholder}
                    selectionColor={Colors.tintColor}
                    returnKeyType={'search'}
                    onFocus={this.handleFocus}
                    autoCorrect={false}
                ></TextInput>
                <TouchableOpacity onPress={() => { return this.handleAction(this.state.value, this.state.actionState === 'times') }} style={styles.searchWrapper}>
                    <FontAwesome5 name={this.state.actionState} color={Colors.tintColor} style={styles.searchIcon} />
                </TouchableOpacity>
            </View>
        </View>;
    }

}

const hasNotch = getStatusBarHeight() > 43;

const styles = StyleSheet.create({
    header: {
        paddingVertical: 25,
        paddingHorizontal: 8,
        backgroundColor: Colors.tintColor,
        height: (hasNotch ? 105 : 80)
    },
    inputWrapper: {
        flexDirection: 'row',
        paddingLeft: 15,
        paddingRight: 5,
        marginTop: (hasNotch ? 25 : 5),
        borderRadius: 15,
        width: '100%',
        backgroundColor: Colors.backgroundColor,
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    searchWrapper: {
        marginLeft: 15
    },
    searchIcon: {
        fontSize: 20
    },
    input: {
        height: 40,
        lineHeight: 18,
        width: '85%'
    }
});