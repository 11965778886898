import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";
import moment from 'moment';
import 'moment/locale/pt-br';

import { getNavigationOptions } from '../helper/NavigationHelper';
import SessionHelper from '../helper/SessionHelper';
import SATHelper from '../helper/SATHelper';

import Firestore from '../api/firebase/Firestore';

import Colors from '../constants/Colors';

import LoadingOverlay from '../components/LoadingOverlay';
import DefaultInput from '../components/DefaultInput';
import DefaultButton from '../components/DefaultButton';


export default class AddReplacedPartsScreen extends React.Component {
	state = {
		loading: true,
		technicianId: SessionHelper.getTechnician().id,
		userId: SessionHelper.getFirebaseAuth().uid,
		infoInputs: {
			name: '',
			code: 0,
			quantity: 0
		},
		sat: null,
	}

	async componentDidMount() {
		this.setState({ loading: true });

		await this.setNavigationData(this.props.navigation.state.params);

		await this.syncSat();

		this.setState({ loading: false });
	}

	setNavigationData = async (params) => {
		await this.setState({ sat: params.sat });
	}

	syncSat = async () => {
		const query = await Firestore.getDoc('appointment', this.state.sat.id);

		let sat = query.data();
		sat.id = query.id;

		this.setState({ sat });
	}

	static navigationOptions = ({ navigation }) => {
		return getNavigationOptions(`Adicionar Peça ${navigation.state.params.sat.title.replace('Assistência ', '')}`);
	};

	handleInputChange(value, field) {
		let inputs = this.state.infoInputs;

		if (inputs.hasOwnProperty(field)) {
			inputs[field] = value;
			this.setState({ infoInputs: inputs });
		}
	}

	renderForm = () => {
		return (
			<View style={styles.container}>
				<ScrollView style={{ width: '100%' }}>
					<View style={{ alignItems: 'center' }}>
						<View style={{ alignItems: 'flex-start', maxWidth: '90%' }}>
							<Text style={{ paddingHorizontal: 20, paddingTop: 10, fontSize: 16, color: Colors.text, fontWeight: '600', }}>Preencha as informações da peça que foi substituída.</Text>
							<Text style={{ paddingHorizontal: 20, paddingTop: 10, fontSize: 14, color: Colors.contrastColor, fontWeight: '600', }}>Campos obrigatórios estão marcados com (*)</Text>
						</View>

						<View style={styles.formContainer}>
							<DefaultInput
								value={this.state.infoInputs.name}
								onChangeText={(value) => { this.handleInputChange(value, 'name') }}
								autoCapitalize={'words'}
								returnKeyType={'next'}
								placeholder={'Nome*'}
								autoCorrect={false}
								onSubmitEditing={() => { this.codeInput.inputRef.focus() }}
								blurOnSubmit={false}
							/>
							<DefaultInput
								ref={(input) => { this.codeInput = input; }}
								value={this.state.infoInputs.code}
								onChangeText={(value) => { this.handleInputChange(value, 'code') }}
								returnKeyType={'done'}
								keyboardType={'number-pad'}
								placeholder={'Código*'}
								autoCorrect={false}
								onSubmitEditing={() => { this.quantityInput.inputRef.focus() }}
								blurOnSubmit={false}
							/>
							<DefaultInput
								ref={(input) => { this.quantityInput = input; }}
								value={this.state.infoInputs.quantity}
								onChangeText={(value) => { this.handleInputChange(value, 'quantity') }}
								returnKeyType={'done'}
								keyboardType={'number-pad'}
								placeholder={'Quantidade*'}
								autoCorrect={false}
								blurOnSubmit={false}
							/>
						</View>

						<View style={{ alignItems: 'center', marginTop: 30, width: '83%' }}>

							<DefaultButton
								solidIcon
								icon={'wrench'}
								title={'Adicionar Peça Substitída'}
								onPress={() => { this.saveInfo() }}
							/>

						</View>
					</View>
				</ScrollView>
			</View>
		)
	}

	saveInfo = async () => {
		try {
			let errorMessage;
			let valid = true;

			Object.keys(this.state.infoInputs).forEach(key => {
				if (key) {
					if (this.state.infoInputs[key] === '' || this.state.infoInputs[key] === 0) {
						errorMessage = 'Preencha todos os campos obrigatórios (*)';
						valid = false;
					}
				}
			});

			if (!valid) {
				showMessage({
					icon: 'danger',
					message: errorMessage,
					type: "danger",
				});
			}


			if (valid) {
				this.setState({ loading: true });

				const replacedPart = {
					name: this.state.infoInputs.name,
					code: parseInt(this.state.infoInputs.code),
					quantity: parseInt(this.state.infoInputs.quantity),
					date: new Date(),
					technician_id: this.state.technicianId,
					user_id: this.state.userId
				}

				if (!this.state.sat.replacedParts || this.state.sat.replacedParts.length < 1) {
					this.state.sat.replacedParts = [replacedPart];
				} else {
					this.state.sat.replacedParts.push(replacedPart);
				}

				await Firestore.update({ replacedParts: this.state.sat.replacedParts },
					'appointment', this.state.sat.id)

				await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico adicionou peça ${replacedPart.name}`, new Date());

				showMessage({
					icon: 'success',
					message: 'Peça adicionada com suceso!',
					type: "success",
				});

				this.props.navigation.goBack();

				this.setState({ loading: false });
			}

		} catch (error) {
			showMessage({
				icon: 'danger',
				message: 'Erro ao salvar',
				type: "danger",
			});

			this.setState({ loading: false });
		}
	}

	render() {
		return this.state.loading ? <LoadingOverlay title={'Aguarde, executando operacão...'} /> : this.renderForm();
	}

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	cardWrapper: {
		padding: 10
	},
	card: {
		width: '100%',
		paddingVertical: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 130,
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.tintColor,
		marginTop: 10,
		height: 68,
		width: 68,
		borderRadius: 68,
	},
	image: {
		aspectRatio: 1 / 1,
		height: 64,
		width: 64,
		borderRadius: 64
	},
	technicianBubbleText: {
		textAlign: 'center',
		paddingVertical: 8,
		fontSize: 12,
		color: Colors.text,
		fontWeight: "500",
		paddingHorizontal: 3
	},
	cardHeader: {
		flexDirection: 'row',
		width: '100%',
		marginBottom: 5,
		padding: 5,
		justifyContent: 'flex-start',
		textAlignVertical: 'center'
	},
	cardHeaderText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: Colors.tintColor,
		marginLeft: 5
	},
	cardHeaderIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginRight: 5
	},
	floatingButton: {
		position: 'absolute',
		bottom: 10,
		justifyContent: 'center',
		alignSelf: 'center',
		width: '90%'
	},
	formContainer: {
		width: '90%',
		height: 'auto'
	},
});
