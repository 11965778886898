import * as React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ActivityIndicator, Linking } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";
import { TextInputMask } from 'react-native-masked-text';
import moment from 'moment';
import 'moment/locale/pt-br';

import { getNavigationOptions, toggleStatusBarStyle } from '../helper/NavigationHelper';
import SessionHelper from '../helper/SessionHelper';
import MediaPicker from '../helper/MediaPicker';
import CurrencyHelper from '../helper/CurrencyHelper';
import SATHelper from '../helper/SATHelper';
import GeographicHelper from '../helper/GeographicHelper';

import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';

import Colors from '../constants/Colors';

import LoadingOverlay from '../components/LoadingOverlay';
import Badge from '../components/Badge';
import Modal from '../components/Modal';
import DefaultButton from '../components/DefaultButton';
import DefaultInput from '../components/DefaultInput';


export default class SatScreen extends React.Component {
	state = {
		loading: true,
		loadingAttachment: false,
		technicianId: null,
		checkedOut: true,
		userId: SessionHelper.getFirebaseAuth().uid,
		sat: null,
		technicianAppointment: null,
		participantTechnicians: [],
		badgeModal: false,
		receiptModal: false,
		filePreviewModal: false,
		currentFilePreview: {},
		receiptValue: '',
		badgeTechnician: {},
		company: null
	}

	async componentDidMount() {
		const { navigation } = this.props;
		navigation.setParams({
			addReceipt: this.addReceipt,
			addMedia: this.addMedia,
			uploadFile: this.uploadFile,
			showReceiptModal: this.showReceiptModal,
			pickMedia: this.pickMedia
		});

		await this.setNavigationData(this.props.navigation.state.params);
		await this.getDocs();

		this.willFocusSubscription = this.props.navigation.addListener(
			'willFocus',
			() => {
				this.syncSat();
			}
		);

		this.setState({ loading: false });
	}

	componentWillUnmount() {
		this.willFocusSubscription.remove();
	}

	async setNavigationData(params) {
		this.setState({
			technicianId: SessionHelper.getTechnician().id,
			sat: params.sat,
			technicianAppointment: params.technicianAppointment,
			receipts: params.sat.receipts ? params.sat.receipts : [],
			media: params.sat.media ? params.sat.media : []
		});
	}

	async getDocs() {
		await this.getTechnicians();
		await this.getCompany();
		await this.syncSat();
		await this.getCheckedOut();
	}

	getCheckedOut = async () => {
		const sat = this.state.sat;

		let output = false;

		if (sat.checkedOutTechnicians) {
			if (sat.checkedOutTechnicians.length > 0) {
				sat.checkedOutTechnicians.forEach(item => {
					if (item.technician_id === this.state.technicianId) {
						output = true;
					}
				})
			}
		}

		this.setState({ checkedOut: output });
	}

	triggerCheckOutMessage() {
		showMessage({ icon: 'warning', message: "Você já fez checkout, agora só pode visualizar!", type: "warning" });
	}

	async getCompany() {
		const doc = await Firestore.getDoc('company', this.state.sat.company_id);

		this.setState({ company: doc.data() });
	}

	async getTechnicians() {
		const query = await Firestore.customQuery('technician').where('active', '==', 'S').get();

		const participantTechnicians = [];

		Object.keys(this.state.sat.technicians).forEach(key => {
			const satTech = this.state.sat.technicians[key];

			query.forEach(technician => {
				if (satTech.id_technician === technician.id) {
					let currentTech = technician.data();
					currentTech.id = technician.id;

					participantTechnicians.push(currentTech);
				}
			});
		});


		this.setState({ participantTechnicians });
	}

	static navigationOptions = ({ navigation }) => {
		const { params = {} } = navigation.state;


		return getNavigationOptions(navigation.state.params.sat.title,
			<View style={{ flexDirection: 'row' }}>
				<TouchableOpacity onPress={() => { params.pickMedia(params.addMedia, params) }}>
					<FontAwesome5 color={'white'} style={{ paddingHorizontal: 10 }} size={22} name={'paperclip'} />
				</TouchableOpacity>
				<TouchableOpacity onPress={() => { params.showReceiptModal() }}>
					<FontAwesome5 color={'white'} style={{ paddingHorizontal: 10 }} size={22} name={'dollar-sign'} />
				</TouchableOpacity>
			</View>
		);
	};

	pickMedia = async (callback, params) => {
		if (!this.state.checkedOut) {
			MediaPicker.pickMedia(callback, params);
		} else {
			this.triggerCheckOutMessage();
		}
	}

	addMedia = async (photo, instance) => {
		this.setState({ loadingAttachment: true });
		await this.syncSat();

		if (!photo.cancelled && photo.uri) {
			if (!this.state.sat.attachments) this.state.sat.attachments = [];

			const fileUri = await this.uploadFile(photo.uri, 'sat_attatchments');

			if (fileUri && this.state.technicianId) {
				this.state.sat.attachments.push({ link: fileUri, date: new Date(), user_id: this.state.userId, name: `Mídia #${moment().format('DDMMYYYYHHmmss')}` });
				const data = { attachments: this.state.sat.attachments };

				try {
					await Firestore.update(data, 'appointment', this.state.sat.id);
					showMessage({ icon: 'success', message: "Mídia anexada com sucesso", type: "success" });
				} catch (error) {

					showMessage({ icon: 'error', message: "Erro ao atualizar SAT", type: "error" });
				}
			} else {
				showMessage({ icon: 'error', message: "Ocorreu um erro ao anexar, tente novamente mais tarde.", type: "error" });
				this.setState({ loadingAttachment: false });
			}
		}

		await this.syncSat();
		this.setState({ loadingAttachment: false });
	}

	syncSat = async () => {
		const query = await Firestore.getDoc('appointment', this.state.sat.id);

		let sat = query.data();
		sat.id = query.id;

		this.setState({ sat });
	}

	addReceipt = async (photo, instance) => {
		this.setState({ receiptModal: false, loadingAttachment: true });
		await this.syncSat();

		if (!photo.cancelled && photo.uri) {
			if (!this.state.sat.expenses) this.state.sat.expenses = [];

			const fileUri = await this.uploadFile(photo.uri, 'technician_receipts');

			if (fileUri && this.state.technicianId && this.state.receiptValue) {
				this.state.sat.expenses.push({ link: fileUri, date: new Date(), user_id: this.state.userId, type: 'receipt', value: CurrencyHelper.moneyToCents(this.state.receiptValue), name: `Recibo #${moment().format('DDMMYYYYHHmmss')}` });
				const data = { expenses: this.state.sat.expenses };

				try {
					await Firestore.update(data, 'appointment', this.state.sat.id);
					showMessage({ icon: 'success', message: "Comprovante anexado com sucesso", type: "success" });
				} catch (error) {
					showMessage({ icon: 'error', message: "Erro ao atualizar SAT", type: "error" });
				}
			} else {
				showMessage({ icon: 'error', message: "Ocorreu um erro ao anexar, tente novamente mais tarde.", type: "error" });
				this.setState({ loadingAttachment: false });
			}
		}

		await this.syncSat();
		this.setState({ loadingAttachment: false });
	}

	uploadFile = async (uri, path) => {
		let output;

		try {
			const upload = await Storage.uploadImageFromPicker(path, uri);

			console.log(upload);

			const fileUri = await Storage.getFileFromRef(upload);
			output = fileUri;
		} catch (e) {
			showMessage({ icon: 'error', message: "Houve um erro ao anexar o arquivo", type: "error" });
			output = false;
		}

		return output;
	}

	removeFile = async (file, type) => {
		this.setState({ filePreviewModal: false, currentFilePreview: {}, loadingAttachment: true });

		try {
			let newArray = [];

			this.state.sat[type].forEach((current, key) => {
				if (current.link !== file.link) {
					newArray.push(current);
				}
			});

			await SATHelper.historyIteration(this.state.sat.id, this.state.userId, 'Técnico removeu anexo da SAT (App Técnico)', new Date(), file.link);

			let updateData = { [type]: newArray };

			await Firestore.update(updateData, 'appointment', this.state.sat.id);

			await this.syncSat();

			showMessage({ icon: 'success', message: "Removido com sucesso!", type: "success" });
		} catch (e) {
			showMessage({ icon: 'error', message: "Erro ao remover arquivo", type: "error" });
			this.setState({ loadingAttachment: false });
		}

		this.setState({ loadingAttachment: false });
	}

	treatName(string) {
		string = string.toLowerCase();
		string = string.charAt(0).toUpperCase() + string.slice(1);
		string = string.split(/\s/);

		return `${string[0]} ${string[string.length - 1][0].toUpperCase()}.`;
	}

	openMap(coords, label) {
		const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });

		if (!label) label = 'Endereço provido pelo Semil Connect';

		const url = Platform.select({
			ios: `${scheme}${label}@${coords}`,
			android: `${scheme}${coords}(${label})`
		});

		Linking.openURL(url);
	}

	seeCompanyAddress = () => {
		if (this.state.company.invalidAddress || !this.state.company.coordinates) {
			showMessage({ icon: 'warning', message: "Desculpe, o endereço dessa empresa é inválido", type: "warning" });
			return;
		}

		const coords = GeographicHelper.getLatLngFromGeopoint(this.state.company.coordinates);

		this.openMap(`${coords.lat},${coords.lng}`, this.state.company.name);
	}

	badgeModal() {
		return (
			<View>
				<Badge photo={this.state.badgeTechnician.photo} name={this.state.badgeTechnician.name} cpf={this.state.badgeTechnician.cpf} />
			</View>
		);
	}

	showReceiptModal = () => {
		if (!this.state.checkedOut) {
			this.setState({ receiptModal: true, receiptValue: '' });
		} else {
			this.triggerCheckOutMessage();
		}
	}

	receiptValueModal() {
		return (
			<View>
				<View style={{ backgroundColor: Colors.backgroundColor, borderRadius: 20, marginTop: 10 }}>
					<Text style={{ color: Colors.text, fontSize: 18, padding: 15, fontWeight: '600', textAlign: 'center' }}>Digite no campo abaixo o valor do recibo que você vai adicionar</Text>
					<View style={{ marginTop: 10, marginBottom: 30, marginHorizontal: 60 }}>
						<TextInputMask
							includeRawValueInChangeText={true}
							customTextInput={DefaultInput}
							customTextInputProps={{ placeholder: 'Valor do Recibo (R$)' }}
							type={'money'}
							value={this.state.receiptValue}
							onChangeText={(text, raw) => { this.setState({ receiptValue: raw }) }}
							options={{ unit: 'R$ ' }}
							fontSize={20}
							textAlign={'center'}
						/>
					</View>
					<View style={{ alignSelf: 'center', marginBottom: 30, width: '85%' }}><DefaultButton onPress={() => { !this.state.receiptValue ? showMessage({ icon: 'warning', message: "Digite o valor do comprovante para prosseguir!", type: "warning" }) : MediaPicker.pickMedia(this.addReceipt, this, [4, 3]) }} title={'Prosseguir'} /></View>
				</View>
			</View>
		);
	}

	filePreviewModal() {
		return (
			<View>
				<View style={{ backgroundColor: Colors.backgroundColor, borderRadius: 20, marginTop: 10 }}>
					<View style={{ maxHeight: 600, width: '100%', padding: 15 }}>
						<Image style={{ width: '100%', height: 'auto', aspectRatio: 1 }} source={{ uri: this.state.currentFilePreview.link }}></Image>
					</View>

					{!this.state.checkedOut ? <View style={{ alignSelf: 'center', marginBottom: 30, width: '85%' }}><DefaultButton color={Colors.secondaryTintColor} onPress={() => { this.removeFile(this.state.currentFilePreview, this.state.currentFilePreview.field) }} title={'Excluir arquivo'} /></View> : null}
				</View>
			</View>
		);
	}

	renderTechnicianBubble = (technician) => {
		let name = this.treatName(technician.name);

		let photo;

		if (technician.photo) {
			photo = (
				<View style={styles.imageWrapper}>
					<Image style={styles.image} source={{ uri: technician.photo }}></Image>
				</View>
			);
		} else {
			photo = (
				<View style={styles.imageWrapper}>
					<FontAwesome5 solid={true} size={32} name={'user-cog'} color={'white'} />
				</View>
			)
		}


		return (
			<TouchableOpacity style={{
				alignItems: 'center',
				justifyContent: 'center',
				paddingHorizontal: 8,
			}} onPress={() => { this.setState({ badgeTechnician: technician, badgeModal: true }) }} key={technician.cpf}>
				{photo}
				<Text style={styles.technicianBubbleText}>{name}</Text>
			</TouchableOpacity>
		);
	}

	renderTechniciansRow = (technicians) => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderCardHeader('users-cog', 'Equipe')}

				<View style={[styles.card, {
					flexDirection: 'row',
					alignItems: 'flex-start',
				}]}>
					<ScrollView showsHorizontalScrollIndicator={false} style={{ width: '100%' }} horizontal={true}>
						{
							technicians.map(technician => this.renderTechnicianBubble(technician))
						}
					</ScrollView>
				</View>
			</View>
		)
	}

	renderCardHeader(icon, title) {
		return (
			<View style={styles.cardHeader}>
				<FontAwesome5 style={styles.cardHeaderIcon} light name={icon} />
				<Text style={styles.cardHeaderText}>{title}</Text>
			</View>
		);
	}

	renderCardFooterWithEvent(icon, title, event) {
		return (
			<TouchableOpacity style={styles.cardFotter} onPress={() => { event ? event() : null }}>
				<Text style={styles.cardFotterText}>{title}</Text>
				<FontAwesome5 style={styles.cardFotterIcon} light name={icon} />
			</TouchableOpacity>
		);
	}

	renderCompanyCard = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderCardHeader('industry', 'Empresa')}

				<View style={styles.card}>

					<View style={{ justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 10 }}>
						<View style={[styles.imageWrapper, { height: 86, width: 86 }]}>
							<FontAwesome5 solid={true} size={42} name={'industry'} color={'#fafafa'} />
						</View>

						<View style={{ maxWidth: '80%', justifyContent: 'center', paddingLeft: 15 }}>
							<Text style={{ fontWeight: '600', color: Colors.text, fontSize: 16, paddingVertical: 5 }}>{this.state.company.name}</Text>
							<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 16, paddingVertical: 5 }}>{this.state.company.google_formatted_address ? this.state.company.google_formatted_address : 'Endereço indisponível'}</Text>
						</View>
					</View>
					{this.renderCardFooterWithEvent('map-marker-alt', 'Ver endereço no mapa', () => { this.seeCompanyAddress() })}
				</View>
			</View>
		);
	}

	renderMediaBubble = (media) => {
		let photo = (
			<View style={styles.imageWrapper}>
				<FontAwesome5 solid={true} size={32} name={'file-image'} color={'white'} />
			</View>
		);

		media.field = 'attachments';

		return (
			<TouchableOpacity style={{
				alignItems: 'center',
				justifyContent: 'center',
				paddingHorizontal: 8,
			}} onPress={() => { this.setState({ currentFilePreview: media, filePreviewModal: true }) }} key={media.name}>
				{photo}
				<Text style={styles.technicianBubbleText}>{media.name}</Text>
			</TouchableOpacity>
		);
	}

	renderMediaRow = (media) => {
		if ((!media.length || !media) && !this.state.loadingAttachment) return this.renderEmptyCard('paperclip', 'Anexos', 'paperclip', 'Você não ainda adicionou nenhum anexo nesta SAT.');

		if (this.state.loadingAttachment) {
			return (
				<View style={styles.cardWrapper}>
					{this.renderCardHeader('paperclip', 'Anexos')}


					<View style={[styles.card, {
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center'
					}]}>
						<ActivityIndicator size={'large'} />
					</View>
				</View>
			)
		} else {
			return (
				<View style={styles.cardWrapper}>
					{this.renderCardHeader('paperclip', 'Anexos')}

					<View style={[styles.card, {
						flexDirection: 'row',
						alignItems: 'flex-start',
					}]}>
						<ScrollView showsHorizontalScrollIndicator={false} style={{ width: '100%' }} horizontal={true}>
							{
								media.reverse().map(current => current.user_id === this.state.userId && current.type !== 'signature' ? this.renderMediaBubble(current) : null)

							}
						</ScrollView>
					</View>
				</View>
			)
		}
	}

	renderReceiptsBubble = (receipt) => {
		let photo = (
			<View style={styles.imageWrapper}>
				<FontAwesome5 solid={true} size={32} name={'file-invoice-dollar'} color={'white'} />
			</View>
		);

		receipt.field = 'expenses';

		return (
			<TouchableOpacity style={{
				alignItems: 'center',
				justifyContent: 'center',
				paddingHorizontal: 8,
			}} onPress={() => { this.setState({ currentFilePreview: receipt, filePreviewModal: true }) }} key={receipt.name}>
				{photo}
				<Text style={styles.technicianBubbleText}>{receipt.name}</Text>
			</TouchableOpacity>
		);
	}

	renderEmptyCard(headerIcon, headerTitle, icon, title) {
		return (
			<View style={styles.cardWrapper}>
				{this.renderCardHeader(headerIcon, headerTitle)}

				<View style={[styles.card, {
					flexDirection: 'row',
					alignItems: 'flex-start'
				}]}>
					<View style={{ justifyContent: 'center', flexDirection: 'row', paddingHorizontal: 10 }}>
						<View style={[styles.imageWrapper, { height: 86, width: 86 }]}>
							<FontAwesome5 light size={42} name={icon} color={'#fafafa'} />
						</View>

						<View style={{ maxWidth: '75%', justifyContent: 'center', paddingLeft: 15 }}>
							<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 16, paddingVertical: 5 }}>{title}</Text>
						</View>
					</View>
				</View>
			</View>
		);
	}

	renderPaymentReceiptsRow = (receipts) => {
		if (!receipts && !this.state.loadingAttachment) return this.renderEmptyCard('dollar-sign', 'Comprovantes de pagamento', 'dollar-sign', 'Você ainda não adicionou nenhum comprovante nesta SAT.');

		if (this.state.loadingAttachment) {
			return (
				<View style={styles.cardWrapper}>
					{this.renderCardHeader('dollar-sign', 'Comprovantes de pagamento')}

					<View style={[styles.card, {
						flexDirection: 'row',
						alignItems: 'center',
						justifyContent: 'center'
					}]}>
						<ActivityIndicator size={'large'} />
					</View>
				</View>
			)
		} else {
			return (
				<View style={styles.cardWrapper}>
					{this.renderCardHeader('dollar-sign', 'Comprovantes de pagamento')}

					<View style={[styles.card, {
						flexDirection: 'row',
						alignItems: 'flex-start',
					}]}>
						<ScrollView showsHorizontalScrollIndicator={false} style={{ width: '100%' }} horizontal={true}>
							{
								receipts.reverse().map(receipt => receipt.user_id === this.state.userId ? this.renderReceiptsBubble(receipt) : null)
							}
						</ScrollView>
					</View>
				</View>
			)
		}

	}

	renderHourHistory = (history) => {
		if (!history) return this.renderEmptyCard('clock', 'Histórico de horas', 'clock', 'Você ainda não apontou horas nessa SAT.');

		let content = (
			<ScrollView style={{ width: '100%', paddingHorizontal: 5 }}>
				{history.reverse().map((item, key) => item.user_id === this.state.userId ? this.renderHistoryRow(item, key) : null)}
			</ScrollView>
		);

		if (this.state.loadingCard) {
			content = (
				<View style={{ width: '100%' }}>
					<ActivityIndicator size={'large'} />
				</View>
			)
		}

		return (
			<View style={[styles.cardWrapper, { paddingVertical: 0 }]}>
				{this.renderCardHeader('clock', 'Histórico de horas')}

				<View style={[styles.card, {
					flexDirection: 'column',
					alignItems: 'flex-start',
					justifyContent: 'center',
					height: 280,
					paddingVertical: 0
				}]}>
					{content}
				</View>
			</View>
		)
	}

	removeHour = async (item, arrayPosition) => {
		if (!this.state.checkedOut) {
			this.setState({ loadingCard: true });

			showMessage({ icon: '', message: "Removendo apontamento de hora", type: "" });

			try {
				await this.syncSat();

				this.state.sat.technicianHours.splice(arrayPosition, 1);

				await Firestore.update({ technicianHours: this.state.sat.technicianHours },
					'appointment', this.state.sat.id)

				if (item.endDate) {
					await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico apagou registro de ${item.minutesWorked} de ${moment(item.startDate.toDate()).format('DD/MM/YYYY HH:mm')} até ${moment(item.endDate.toDate()).format('DD/MM/YYYY HH:mm')}`, new Date());
				} else {
					await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico apagou registro de início de trabalho de ${moment(item.startDate.toDate()).format('DD/MM/YYYY HH:mm')}`, new Date());
				}

				showMessage({ icon: 'success', message: "Removido com sucesso", type: "success" });

				await this.syncSat();

				this.setState({ loadingCard: false });
			} catch (e) {
				showMessage({ icon: 'danger', message: "Ocorreu um erro ao remover", type: "danger" });
				this.setState({ loadingCard: false });
			}


		} else {
			showMessage({ icon: 'danger', message: "Você não pode remover apontamentos de hora após finalizar.", type: "danger" });
		}
	}

	renderHistoryRow = (item, key) => {
		return (
			<View key={item.startDate + item.endDate ? item.endDate : `no-end`} style={{ borderBottomWidth: .5, borderColor: Colors.contrastColor, width: '100%', padding: 10, flexDirection: 'row' }}>
				<View style={[styles.imageWrapper, { height: 32, width: 32 }]}>
					<FontAwesome5 light style={{ marginLeft: 1 }} size={18} name={'clock'} color={'#fafafa'} />
				</View>
				<View style={{ maxWidth: '80%', justifyContent: 'center', paddingLeft: 15, flexDirection: 'column' }}>
					<Text style={{ fontWeight: '600', color: Colors.text, fontSize: 18, paddingVertical: 5 }}>{item.endDate ? item.minutesWorked : `--`} minutos</Text>
					<Text style={{ fontWeight: '500', color: Colors.text, fontSize: 14, paddingVertical: 2 }}>{moment(item.startDate.toDate()).format('DD/MM/YYYY HH:mm')} ~ {item.endDate ? moment(item.endDate.toDate()).format('DD/MM/YYYY HH:mm') : `Final não apontado`}</Text>
					<Text style={{ fontWeight: '400', color: Colors.text, fontSize: 14, paddingVertical: 2 }}>{item.description ? item.description : 'Sem Descrição'}</Text>
				</View>
				<TouchableOpacity onPress={() => { this.removeHour(item, key) }} style={[styles.imageWrapper, { height: 32, width: 32, position: 'absolute', right: 15, top: 18, backgroundColor: Colors.secondaryTintColor }]}>
					<FontAwesome5 light style={{ marginLeft: 1 }} size={18} name={'trash'} color={'#fafafa'} />
				</TouchableOpacity>
			</View>
		);
	}

	renderFinishButton = () => {
		if (!this.state.checkedOut) {
			return (
				<View style={styles.floatingButton}>
					<View style={{ width: '49%', marginRight: '1%' }}>
						<DefaultButton title={"Apontar Horas"} icon={"clock"} color={Colors.secondaryTintColor} onPress={() => { this.props.navigation.navigate('RegisterHours', { sat: this.state.sat }) }} />
					</View>
					<View style={{ width: '49%', marginLeft: '1%' }}>
						<DefaultButton title={"Finalizar SAT"} icon={"check"} onPress={() => { this.props.navigation.navigate('FinishSat', { sat: this.state.sat }) }} />
					</View>
				</View>
			);
		}
	}

	renderContent = () => {
		return (
			<View style={styles.container}>
				<ScrollView>
					{this.renderTechniciansRow(this.state.participantTechnicians)}
					{this.renderCompanyCard()}
					{this.renderMediaRow(this.state.sat.attachments)}
					{this.renderPaymentReceiptsRow(this.state.sat.expenses)}
					{this.renderHourHistory(this.state.sat.technicianHours)}

					<View style={{ height: 80, backgroundColor: 'transparent' }}></View>
				</ScrollView>
				{this.renderFinishButton()}
				<Modal onClose={() => { this.setState({ badgeModal: false }) }} animation={'slide'} visible={this.state.badgeModal} content={this.badgeModal()} />
				<Modal onClose={() => { this.setState({ receiptModal: false }) }} animation={'slide'} visible={this.state.receiptModal} content={this.receiptValueModal()} />
				<Modal onClose={() => { this.setState({ filePreviewModal: false }) }} animation={'slide'} visible={this.state.filePreviewModal} content={this.filePreviewModal()} />
			</View>

		);
	}

	render() {
		return this.state.loading ? <LoadingOverlay title={'Carregando SAT...'} /> : this.renderContent();
	}

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	cardWrapper: {
		padding: 10
	},
	card: {
		width: '100%',
		paddingVertical: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 130,
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.contrastColor,
		marginTop: 10,
		height: 68,
		width: 68,
		borderRadius: 68,
	},
	image: {
		aspectRatio: 1 / 1,
		height: 64,
		width: 64,
		borderRadius: 64
	},
	technicianBubbleText: {
		textAlign: 'center',
		paddingVertical: 8,
		fontSize: 12,
		color: Colors.text,
		fontWeight: "500",
		paddingHorizontal: 3
	},
	cardHeader: {
		flexDirection: 'row',
		width: '100%',
		marginBottom: 5,
		padding: 5,
		justifyContent: 'flex-start',
		textAlignVertical: 'center'
	},
	cardHeaderText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: Colors.tintColor,
		marginLeft: 5
	},
	cardHeaderIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginRight: 5
	},
	floatingButton: {
		flexDirection: 'row',
		position: 'absolute',
		bottom: 10,
		paddingHorizontal: 10,
		width: '100%'
	},
	cardFotter: {
		flexDirection: 'row',
		width: '100%',
		marginTop: 10,
		padding: 15,
		borderTopWidth: .5,
		borderTopColor: 'lightgrey',
		justifyContent: 'flex-end',
		textAlignVertical: 'center'
	},
	cardFotterText: {
		fontSize: 16,
		color: Colors.contrastColor,
		marginRight: 5
	},
	cardFotterIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginLeft: 5
	},
});
