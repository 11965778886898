import React from 'react';
import Colors from '../constants/Colors'
import {
    StyleSheet,
    Text,
    View,
    Image,
    Linking,
    StatusBar,
    TouchableOpacity,
    Platform
} from 'react-native';

import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";

import LoadingOverlay from '../components/LoadingOverlay';
import DefaultButton from '../components/DefaultButton';
import DefaultInput from '../components/DefaultInput';

import Auth from './../api/firebase/Auth';
import Firestore from '../api/firebase/Firestore';

import * as firebase from 'firebase';
import FirebaseTest from '../constants/FirebaseTest';
import Constants from 'expo-constants';

export default class LoginScreen extends React.Component {

    state = {
        loading: false,
        email: '',
        password: '',
        errorMessage: '',
        visiblePassword: false
    }

    async componentDidMount() {
        StatusBar.setBarStyle('light-content');
    }

    async handleLogin() {

        let email = this.state.email;
		let password = this.state.password;
		
		if(email == 'test_user@easydata.net.br' && Constants.appOwnership != 'expo') {
			if(firebase.apps.length) {
				const app = firebase.app();
				await app.delete();
			}

			firebase.initializeApp(FirebaseTest);
		}

        if (email && password) {

            this.setState({ loading: true, errorMessage: '' });

            try {

                await Auth.login(email, password);

                this.props.navigation.navigate('AuthNavigator');

            } catch (error) {

                this.setState({ loading: false, errorMessage: 'E-mail ou senha inválidos!', email: '', password: '' });
                
                showMessage({
                    icon: 'danger',
                    message: this.state.errorMessage,
                    type: "danger",
                });
                
            }

        } else {

            this.setState({ errorMessage: 'Preencha todos os campos!' });
        }
    }

    render() {
        return (
            <View style={style.container}>

                <View style={style.loginContainer}>
                    <View style={{ alignItems: 'center' }}>
                        <Image style={style.logo} source={require('../assets/images/logo.png')}></Image>
                    </View>

                    <DefaultInput
                        value={this.state.email}
                        onChangeText={(value) => { this.setState({ email: value }) }}
                        autoCapitalize={'none'}
                        returnKeyType={'next'}
                        placeholder={'E-mail'}
                        autoCorrect={false}
                        onSubmitEditing={() => { this.passwordInput.inputRef.focus() }}
                        blurOnSubmit={false}
                    />
                    <DefaultInput
                        ref={(input) => { this.passwordInput = input; }}
                        value={this.state.password}
                        onChangeText={(value) => { this.setState({ password: value }) }}
                        secureTextEntry={!this.state.visiblePassword}
                        placeholder={'Senha'}
                        returnKeyType={'done'}
                        autoCapitalize={'none'}
                        autoCorrect={false}
                    />


                    <View style={{ position: "absolute", right: 30, bottom: 108 }}>
                        <TouchableOpacity onPress={() => { this.setState({ visiblePassword: !this.state.visiblePassword }) }}>
                            <FontAwesome5 name={this.state.visiblePassword ? 'eye-slash' : 'eye'} size={20} solid color={Colors.tintColor} />
                        </TouchableOpacity>
                    </View>

                    <View style={{ marginTop: 15 }}>
                        <DefaultButton title={"ENTRAR"} loading={this.state.loading} onPress={() => { this.handleLogin() }} />
                    </View>

                </View>

                <View style={style.terms}>
                    <Text style={style.termsText}><Text style={[style.termsText, { textDecorationLine: 'underline', fontWeight: 'bold', margin: 5 }]}
                        onPress={() => {this.props.navigation.navigate('ForgotPasswordNavigator')}}>
                        Esqueceu sua senha?
                    </Text></Text>
                </View>

            </View>
        )
    }
}

const style = StyleSheet.create({

    container: {
        flex: 1,
        backgroundColor: Colors.tintColor,
        justifyContent: 'center',
        alignItems: 'center'
    },
    loginContainer: {
        marginBottom: 60,
        backgroundColor: Colors.backgroundColor,
        width: Platform.OS === 'web' ? 450 : '90%',
        borderRadius: 25,
        padding: 20
    },
    logo: {
        margin: 15,
        height: 56,
        borderRadius: 5,
		width: 250,
		height: 76
    },
    terms: {
        position: "relative",
        alignSelf: 'center',
        bottom: -45,
    },
    termsText: {
        color: Colors.backgroundColor,
        textAlign: 'center'
    },
    errorMessage: {
        color: Colors.backgroundColor,
        marginBottom: 10,
    }
})