import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View} from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import Colors from '../constants/Colors';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';

export default class NotificationList extends React.Component {
	async navigateToSat() {
		if(this.props.data.appointment_id) {
			const query = await Firestore.getDoc('appointment', this.props.data.appointment_id);
			const doc = query.data();
			doc.id = query.id;

			let technician;

			doc.technicians.forEach(current => {
				if (current.id_technician === SessionHelper.getTechnician().id) technician = current;
			});
			
			this.props.navigation.navigate(this.props.navigateScreen, { sat: doc, technicianAppointment: technician })
		}
	}

    render() {

        return (
            <TouchableOpacity style={style.list} onPress={() => { this.props.navigateScreen ? this.navigateToSat() : null }}>
                <View style={{ flexDirection: 'row', alignItems: 'flex-start',}}>
                    {this.props.icon ? <FontAwesome5 solid color={!this.props.iconColor ? Colors.regularText : this.props.iconColor} style={style.icon} name={this.props.icon} /> : null}
                    <Text style={style.text}>{this.props.title}</Text>
                </View>
                <View>
                    { this.props.body ? <Text style={style.body}>{this.props.body}</Text> : null }
                </View>
                <View>
                    {this.props.date ? <Text style={style.date}>{this.props.date}</Text> : null}
                </View>
            </TouchableOpacity>
        );
    }
}

const style = StyleSheet.create({
    date: {
        color: Colors.regularText,
        paddingVertical: 10,
    },
    list: {
        margin: 5,
        paddingTop: 20,
        paddingHorizontal: 20,
        width: '95%',
        borderRadius: 5,
        backgroundColor: '#fff',
        flexDirection: 'column',
        alignItems: 'flex-start',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 1
    },
    text: {
        fontSize: 17,
        fontWeight: 'bold'
    },
    body: {
        color: Colors.regularText,
        paddingTop: 8,
    },
    icon: {
		marginRight: 5,
        fontSize: 20,
        fontWeight: 'bold'
    }
});