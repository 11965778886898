import * as React from 'react';
import { createBottomTabNavigator } from 'react-navigation-tabs';
import { createStackNavigator } from 'react-navigation-stack';
import TabBarIcon from '../components/TabBarIcon';
import HomeScreen from '../screens/HomeScreen';
import BadgeScreen from '../screens/BadgeScreen';
import AccountScreen from '../screens/AccountScreen';
import SatScreen from '../screens/SatScreen';
import FinishSatScreen from '../screens/FinishSatScreen';
import RegisterHoursScreen from '../screens/RegisterHoursScreen';
import CaptureSignatureScreen from '../screens/CaptureSignatureScreen';
import Colors from '../constants/Colors';
import NotificationScreen from '../screens/NotificationScreen';
import SignatureInfoScreen from '../screens/SignatureInfoScreen';
import AddReplacedPartsScreen from '../screens/AddReplacedPartsScreen';
import AddReviewedPartsScreen from '../screens/AddReviewedPartsScreen';
import ReportListScreen from '../screens/ReportListScreen';

const HomeStack = createStackNavigator({
	Home: {
		screen: HomeScreen,
	},
	Badge: {
		screen: BadgeScreen,
	},
	Account: {
		screen: AccountScreen,
	},
	Sat: {
		screen: SatScreen,
	},
	FinishSat: {
		screen: FinishSatScreen
	},
	RegisterHours: {
		screen: RegisterHoursScreen
	},
	CaptureSignature: {
		screen: CaptureSignatureScreen
	},
	SignatureInfo: {
		screen: SignatureInfoScreen
	},
	AddReplacedParts: {
		screen: AddReplacedPartsScreen
	},
	AddReviewedParts: {
		screen: AddReviewedPartsScreen
	}
},
);

HomeStack.navigationOptions = {
	tabBarIcon: ({ focused }) => (
		<TabBarIcon badge={true} focused={focused} name={'tools'} />
	),
};

const AccountStack = createStackNavigator({
	Account: {
		screen: AccountScreen,
	}
},
);

AccountStack.navigationOptions = {
	tabBarIcon: ({ focused }) => (
		<TabBarIcon badge={true} focused={focused} solid name={'user-circle'} />
	),
};

const BadgeStack = createStackNavigator({
	Badge: {
		screen: BadgeScreen,
	}
},
);

BadgeStack.navigationOptions = {
	tabBarIcon: ({ focused }) => (
		<TabBarIcon badge={true} focused={focused} solid name={'id-badge'} />
	),
};

const ReportStack = createStackNavigator({
	ReportList: {
		screen: ReportListScreen
	},
	Sat: {
		screen: SatScreen,
	},
	FinishSat: {
		screen: FinishSatScreen
	},
	RegisterHours: {
		screen: RegisterHoursScreen
	},
	CaptureSignature: {
		screen: CaptureSignatureScreen
	},
	SignatureInfo: {
		screen: SignatureInfoScreen
	},
	AddReplacedParts: {
		screen: AddReplacedPartsScreen
	},
	AddReviewedParts: {
		screen: AddReviewedPartsScreen
	}
},
);

ReportStack.navigationOptions = {
	tabBarIcon: ({ focused }) => (
		<TabBarIcon badge={true} focused={focused} solid name={'chart-bar'} />
	),
};

const NotificationStack = createStackNavigator({
	Notification: {
		screen: NotificationScreen,
	},
	Sat: {
		screen: SatScreen,
	},
	FinishSat: {
		screen: FinishSatScreen
	},
	RegisterHours: {
		screen: RegisterHoursScreen
	},
	CaptureSignature: {
		screen: CaptureSignatureScreen
	},
	SignatureInfo: {
		screen: SignatureInfoScreen
	},
	AddReplacedParts: {
		screen: AddReplacedPartsScreen
	},
	AddReviewedParts: {
		screen: AddReviewedPartsScreen
	}
},
);

NotificationStack.navigationOptions = {
	tabBarIcon: ({ focused }) => (
		<TabBarIcon badge={true} focused={focused} solid name={'bell'} />
	),
};

const tabNavigatorConfig = {
	tabBarOptions: {
		activeTintColor: Colors.tintColor,
		showLabel: false,
		style: {
			borderTopColor: "transparent",
			shadowColor: "#000",
			shadowOffset: {
				width: 0,
				height: 5,
			},
			shadowOpacity: 0.34,
			shadowRadius: 6.27,

			elevation: 10
		}
	}
}

const tabNavigator = createBottomTabNavigator({
	HomeStack,
	BadgeStack,
	ReportStack,
	NotificationStack,
	AccountStack,
}, tabNavigatorConfig);

export default tabNavigator;
