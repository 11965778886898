import React, { Component } from 'react';
import { View, StyleSheet } from 'react-native';
import { captureRef as takeSnapshotAsync } from 'react-native-view-shot';
import DefaultButton from './DefaultButton';
import Colors from '../constants/Colors';
import ExpoDraw from 'expo-draw';

export default class CaptureSignature extends Component {
	clearCanvas = () => {
		this._clear();
	}

	saveCanvas = async () => {
		const signature_result = await takeSnapshotAsync(this.signatureCanvas, {
			result: 'tmpfile',
			quality: 0.5,
			format: 'png',
		});

		if (this.props.callback) {
			this.props.callback({uri: signature_result});
		}
	}

	render() {
		return (
			<View style={styles.container}>
				<ExpoDraw
					containerStyle={{ backgroundColor: '#ffffff' }}
					clear={(clear) => { this._clear = clear }}
					color={'#000000'}
					strokeWidth={2}
					ref={ref => {this.signatureCanvas = ref}}
				/>

				<View style={{ flexDirection: 'row', justifyContent: 'center' }}>
					<View style={{ width: '49%', paddingHorizontal: '1%' }}>
						<DefaultButton onPress={() => { this.clearCanvas() }} title={'Limpar'} color={Colors.secondaryTintColor} icon={'trash'} />
					</View>
					<View style={{ width: '49%', paddingHorizontal: '1%' }}>
						<DefaultButton onPress={() => { this.saveCanvas() }} title={'Salvar'} icon={'save'} solidIcon />
					</View>
				</View>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#ffffff',
	}
});
