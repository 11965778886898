import React from 'react';
import { StatusBar, View, Alert } from 'react-native';
import SessionHelper from '../helper/SessionHelper';
import Firestore from '../api/firebase/Firestore';
import * as firebase from 'firebase';
import SplashLoading from '../components/SplashLoading';
import GeographicHelper from '../helper/GeographicHelper'
import NotificationHelper from '../helper/NotificationHelper';
import { showMessage } from "react-native-flash-message";

import * as Device from 'expo-device';
import * as Notifications from 'expo-notifications'
import { Platform } from 'expo-modules-core';

const LOCATION_TASK_NAME = 'background-location-task';
export default class AuthNavigator extends React.Component {

	async initFirebase() {

		firebase.auth().onAuthStateChanged(async (credentials) => {

			if (credentials) {
				SessionHelper.setFirebaseAuth(credentials);
				const user = await Firestore.getDoc('user', credentials.uid);

				if (user.exists && user.data().type === 'technician') {
					const technician = await Firestore.customQuery('technician').where('user_id', '==', SessionHelper.getFirebaseAuth().uid).limit(1).get();

					const technicianData = technician.docs[0].data();
					technicianData.id = technician.docs[0].id;

					const userData = user.data();
					userData.id = user.id;

					if (technicianData.active === 'S') {
						try {
							if(Device.isDevice && Platform.OS !== "web") {
								let token = await NotificationHelper.getToken();

								if (!userData.notification_token || userData.notification_token != token) {
	
									if (token) {
										userData.notification_token = token;
										await Firestore.update({ notification_token: token }, 'user', userData.id);
									}
								}
							}
						} catch (error) {
							console.log('---------------erro de notificao-----------------');
							console.log(error);

							showMessage({
								icon: 'warning',
								message: "Não foi possível inicializar as notificações push",
								type: "warning",
							});

						}

						SessionHelper.setData(userData);
						SessionHelper.setTechnician(technicianData);

						if (Device.isDevice && Platform.OS !== "web") await this.initNotifications();

						this.props.navigation.navigate('Root');
					} else {
						showMessage({
							icon: 'danger',
							message: "Você é um técnico inativo. Login proibido.",
							type: "danger",
						});

						this.props.navigation.navigate('LoginNavigator');
					}
				} else {
					showMessage({
						icon: 'warning',
						message: "Você deve ser um técnico para acessar este App",
						type: "warning",
					});

					this.props.navigation.navigate('LoginNavigator');
				}

			} else {
				this.props.navigation.navigate('LoginNavigator');
			}
		});
	}

	async UNSAFE_componentWillMount() {
		this.initFirebase();
	}

	async componentDidMount() {
		if (Device.isDevice && Platform.OS !== "web") this._notificationSubscription = Notifications.addListener(this._handleNotification);
	}

	_handleNotification = notification => {
		if (notification) {
			let object = notification.data;

			if (SessionHelper.getFirebaseAuth() && object.navigateScreen) {

				this.props.navigation.navigate(object.navigateScreen, object.data ? object.data : {});
			}
		}
	};

	async initNotifications() {

		NotificationHelper.checkPermission();

		let docs = await Firestore.getCollection('notification', ['user_id', '==', SessionHelper.getFirebaseAuth().uid]);
		let badgeCount = 0;

		docs.forEach((doc, key) => {
			if (doc.exists && !doc.data().viewed) {
				badgeCount++;
			}
		})

		NotificationHelper.setBadge(badgeCount);
	}

	render() {
		return (
			<View style={{ flex: 1 }}>
				<SplashLoading />
				<StatusBar barStyle="default" />
			</View>
		);
	}
}
