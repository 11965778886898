import * as React from 'react';
import { StyleSheet, Text, View, Image, StatusBar, RefreshControl, Touchable } from 'react-native';
import { FlatList, ScrollView, TouchableOpacity } from 'react-native-gesture-handler';
import Auth from '../api/firebase/Auth';
import Colors from '../constants/Colors';
import Layout from '../constants/Layout';
import { getNavigationOptions, toggleStatusBarStyle } from '../helper/NavigationHelper';
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';
import DateTimePickerModal from "react-native-modal-datetime-picker";

//React native calendar
import moment from 'moment';
import 'moment/locale/pt-br';
import DefaultLoading from '../components/DefaultLoading';
import HourCard from '../components/HourCard';
import { FontAwesome5 } from '@expo/vector-icons';
import SATHelper from '../helper/SATHelper';
import GeographicHelper from '../helper/GeographicHelper';
import firebase from 'firebase';
import { showMessage } from 'react-native-flash-message';

export default class ReportListScreen extends React.Component {
	state = {
		technicianId: SessionHelper.getTechnician().id,
		userId: SessionHelper.getFirebaseAuth().uid,
		endTime: moment().toDate(),
		startTime: moment().subtract(10, 'years').toDate(),
		sat: null,
		hourIndex: null,
		isDatePickerVisible: false,
		userId: SessionHelper.getFirebaseAuth().uid,
		paginationStartDate: moment().startOf('month'),
		paginationEndDate: moment().endOf('month'),
		technician: {},
		hours: [],
		quering: false
	}

	async componentDidMount() {
		await this.setState({ quering: true });

		StatusBar.setBarStyle('light-content');

		await this.getTechnician();
		await this.getHoursPointed();

		await this.setState({ quering: false });
	}

	async getTechnician() {
		const query = await Firestore.customQuery('technician').where('user_id', '==', SessionHelper.getFirebaseAuth().uid).limit(1).get();

		this.setState({ technician: query.docs[0].data() });
	}

	async getHoursPointed() {
		this.setState({ quering: true });

		const query = await Firestore.customQuery('appointment').orderBy('technicianHours', 'desc').get();

		let hours = [];

		query.forEach(doc => {
			doc.data().technicianHours.forEach((hour, index) => {
				if (hour.technician_id === this.state.technicianId) {
					if ((moment(hour.startDate.toDate()).isBetween(moment(this.state.paginationStartDate), moment(this.state.paginationEndDate)))) {
						hours.push({ ...hour, appointment: { ...doc.data(), id: doc.id }, index });
					}
				}
			});
		});

		hours.sort(function (a, b) { return new Date(b.startDate.toDate()) - new Date(a.startDate.toDate()) });

		this.setState({ hours, quering: false });
	}

	renderEmptyContainer = () => {
		if (this.state.quering == true) {
			return (
				<View style={styles.notFoundContainer}>
					<DefaultLoading title={'Carregando período selecionado...'} />
				</View>
			);
		} else {
			return (
				<View style={styles.notFoundContainer}>
					<Image
						style={styles.notFoundImage}
						source={require('../assets/images/undraw/empty.png')}
					/>
					<Text style={styles.notFoundText}>Nenhum registro encontrado no período selecionado.</Text>
				</View>
			);
		}
	}

	renderDatePicker = () => {
		return (
			<DateTimePickerModal
				isVisible={this.state.isDatePickerVisible}
				date={this.state.endTime}
				maximumDate={new Date()}
				minimumDate={this.state.startTime}
				headerTextIOS="Aponte o Final"
				confirmTextIOS="Confirmar"
				cancelTextIOS="Fechar"
				mode="datetime"
				locale={'pt-br'}
				minuteInterval={1}
				isDarkModeEnabled={false}
				onConfirm={(timestamp) => { this.handleSelectDate(timestamp) }}
				onCancel={() => { this.setState({ isDatePickerVisible: false }) }}
			/>
		);
	}

	async handleSelectDate(date) {
		await this.setState({ endTime: date, isDatePickerVisible: false });
		await this.registerHours();
	}

	registerHours = async () => {
		if (!this.state.endTime) {
			showMessage({ icon: 'danger', message: "Ocorreu um erro ao escolher o horário de fim", type: "danger" });
			return;
		}

		const timeDiff = moment(this.state.endTime).diff(this.state.startTime, 'minutes');

		if (this.state.startTime && this.state.endTime && timeDiff <= 1) {
			showMessage({ icon: 'warning', message: "O final não pode antes do início!", type: "warning", duration: 10000, hideOnPress: true });
			return;
		}

		if (this.state.sat.technicianHours && this.state.sat.technicianHours.length) {
			let valid = true;

			this.state.sat.technicianHours.forEach(hour => {
				if (hour.technician_id === this.state.technicianId) {
					if ((moment(this.state.startTime).isBetween(moment(hour.startDate.toDate()), moment(hour.endDate ? hour.endDate.toDate() : hour.startDate.toDate())))
						|| (moment(this.state.endTime).isBetween(moment(hour.startDate.toDate()), moment(hour.endDate ? hour.endDate.toDate() : hour.startDate.toDate())))) {
						valid = false;
					}
				}
			});

			if (!valid) {
				showMessage({ icon: 'warning', message: "Você já apontou horas nesse intervalo de tempo", type: "warning" });
				return;
			}
		}

		this.setState({ quering: true });

		let data = [];

		if (this.state.sat.technicianHours) data = this.state.sat.technicianHours;

		data[this.state.hourIndex] = {
			...data[this.state.hourIndex],
			startDate: moment(this.state.startTime).toDate(),
			endDate: moment(this.state.endTime).toDate(),
			minutesWorked: timeDiff,
		};

		try {
			await Firestore.update({ technicianHours: data }, 'appointment', this.state.sat.id);

			await SATHelper.historyIteration(this.state.sat.id, this.state.userId,
				`Técnico apontou ${timeDiff} minutos de trabalho, de ${moment(this.state.startTime).format('DD/MM/YYYY HH:mm')} à ${moment(this.state.endTime).format('DD/MM/YYYY HH:mm')}. ${this.state.hourDescription ? 'Descreveu como: "' + this.state.hourDescription + '"' : ''}`, new Date());

			showMessage({ icon: 'success', message: "Apontamento de horarário final realizado com sucesso!", type: "success" });

			await this.setState({ hours: [] });
			await this.getHoursPointed();
		} catch (e) {
			showMessage({ icon: 'danger', message: "Erro ao realizar apontamento :(", type: "danger" });

			this.setState({ quering: false });
		}

	}

	removeHour = async (item, arrayPosition) => {
		if (this.state.sat.status == "open") {
			this.setState({ quering: true });

			showMessage({ icon: 'info', message: "Removendo apontamento de hora", type: "info" });

			try {
				this.state.sat.technicianHours.splice(arrayPosition, 1);

				await Firestore.update({ technicianHours: this.state.sat.technicianHours },
					'appointment', this.state.sat.id)

				if (item.endDate) {
					await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico apagou registro de ${item.minutesWorked} de ${moment(item.startDate.toDate()).format('DD/MM/YYYY HH:mm')} até ${moment(item.endDate.toDate()).format('DD/MM/YYYY HH:mm')}`, new Date());
				} else {
					await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico apagou registro de início de trabalho de ${moment(item.startDate.toDate()).format('DD/MM/YYYY HH:mm')}`, new Date());
				}

				showMessage({ icon: 'success', message: "Removido com sucesso", type: "success" });

				await this.setState({ hours: [] });
				await this.getHoursPointed();
			} catch (e) {
				showMessage({ icon: 'danger', message: "Ocorreu um erro ao remover", type: "danger" });
				this.setState({ quering: false });
			}


		} else {
			showMessage({ icon: 'danger', message: "Você não pode remover apontamentos de hora após finalizar.", type: "danger" });
		}
	}

	renderItem = ({ item }) => {
		return (
			<HourCard
				item={item}
				onHourPress={async () => { await this.setState({ sat: item.appointment, hourIndex: item.index, startTime: item.startDate.toDate(), hourDescription: item.hourDescription || ``, isDatePickerVisible: true }) }}
				onDeletePress={async () => { await this.setState({ sat: item.appointment }); await this.removeHour(item, item.index) }}
				navigation={this.props.navigation}
			/>
		)
	}

	nextMonth = async () => {
		this.setState({ paginationEndDate: moment(this.state.paginationEndDate).add(1, 'month').endOf('month'), paginationStartDate: moment(this.state.paginationStartDate).add(1, 'month').startOf('month'), hours: [] });
		await this.getHoursPointed();
	}

	previousMonth = async () => {
		this.setState({ paginationEndDate: moment(this.state.paginationEndDate).subtract(1, 'month').endOf('month'), paginationStartDate: moment(this.state.paginationStartDate).subtract(1, 'month').startOf('month'), hours: [] });
		await this.getHoursPointed();
	}

	renderMonthPicker() {
		return (
			<View style={{ backgroundColor: '#ffffff', width: '100%', height: 80, borderBottomWidth: .5, borderBottomColor: Colors.contrastColor, justifyContent: 'center', alignItems: 'center' }}>
				<View style={{ position: 'absolute', left: 0 }}>
					<TouchableOpacity style={{ height: 64, width: 64, justifyContent: 'center', alignItems: 'flex-start' }} onPress={() => { this.previousMonth() }} ><FontAwesome5 style={{ margin: 20, fontSize: 22, fontWeight: '600', color: Colors.tintColor }} name={'chevron-left'} solid /></TouchableOpacity>
				</View>
				<Text style={{ fontSize: 20, fontWeight: 'bold', color: Colors.tintColor }}>{moment().format('MM') == moment(this.state.paginationStartDate).format('MM') ? <View style={{ height: 13, width: 13, borderRadius: 6.5, backgroundColor: Colors.secondaryTintColor }}></View> : null} {moment(this.state.paginationStartDate).format('MMMM [de] YYYY')}</Text>
				<View style={{ position: 'absolute', right: 0 }}>
					<TouchableOpacity style={{ height: 64, width: 64, justifyContent: 'center', alignItems: 'flex-end' }} onPress={() => { this.nextMonth() }} ><FontAwesome5 style={{ margin: 20, fontSize: 22, fontWeight: '600', color: Colors.tintColor }} name={'chevron-right'} solid /></TouchableOpacity>
				</View>
			</View>
		)
	}

	render() {
		return (
			<View style={styles.container}>
				{this.renderMonthPicker()}

				<FlatList
					refreshControl={<RefreshControl refreshing={this.state.quering} onRefresh={() => { this.setState({ hours: [] }); this.getHoursPointed(); }} />}
					contentContainerStyle={styles.list}
					data={this.state.hours}
					keyExtractor={item => item._id}
					renderItem={this.renderItem}
					ListEmptyComponent={this.renderEmptyContainer()}
				/>

				{this.renderDatePicker()}
			</View>
		);
	}
}

ReportListScreen.navigationOptions = getNavigationOptions('Horas apontadas mensalmente');

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor,
	},
	badgeWrapper: {
		height: '100%'
	},
	notFoundContainer: {
		flex: 1,
		justifyContent: 'center',
		height: Layout.window.height - 280
	},
	notFoundImage: {
		marginTop: 30,
		justifyContent: "center",
		alignSelf: "center",
		width: 182,
		height: 200
	},
	notFoundText: {
		padding: 20,
		fontSize: 16,
		justifyContent: "center",
		textAlign: "center",
		color: 'grey'
	},
});
