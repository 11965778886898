import * as React from 'react';
import { StyleSheet, Text, View } from 'react-native';

import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";
import moment from 'moment';
import 'moment/locale/pt-br';

import { getNavigationOptions } from '../helper/NavigationHelper';
import SessionHelper from '../helper/SessionHelper';
import SATHelper from '../helper/SATHelper';

import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';

import Colors from '../constants/Colors';

import LoadingOverlay from '../components/LoadingOverlay';
import CaptureSignature from '../components/CaptureSignature';


export default class CaptureSignatureScreen extends React.Component {
	state = {
		loading: true,
		loadingAttachment: false,
		technicianId: SessionHelper.getTechnician().id,
		userId: SessionHelper.getFirebaseAuth().uid,
		sat: null,
		signatureInfo: null,
		technicianDescription: ''
	}

	async componentDidMount() {
		const { navigation } = this.props;
		navigation.setParams({
			saveSignature: this.saveSignature
		});

		await this.setNavigationData(this.props.navigation.state.params);

		this.setState({ loading: false });
	}

	setNavigationData = async (params) => {
		await this.setState({sat: params.sat, signatureInfo: params.signatureInfo});
	}

	syncSat = async () => {
		const query = await Firestore.getDoc('appointment', this.state.sat.id);

		let sat = query.data();
		sat.id = query.id;

		this.setState({ sat });
	}

	static navigationOptions = ({ navigation }) => {
		const { params = {} } = navigation.state;

		return getNavigationOptions(`Obter Assinatura ${navigation.state.params.sat.title.replace('Assistência ', '')}`);
	};

	renderHeader(icon, title) {
		return (
			<View style={styles.cardHeader}>
				<FontAwesome5 style={styles.cardHeaderIcon} light name={icon} />
				<Text style={styles.cardHeaderText}>{title}</Text>
			</View>
		);
	}

	saveSignature = async (file) => {
		if(file.uri) {
			await this.addSignature(file);
		} else {
			showMessage({ icon: 'error', message: "Erro ao salvar assinatura", type: "error" });
		}
	}

	addSignature = async (photo) => {
		this.setState({ loading: true });
		await this.syncSat();

		if (photo.uri) {
			//Remove previous signature, to execute the overwrite
			if (this.state.sat.attachments) {
				for(let i = 1; i < this.state.sat.attachments.length; i++) {
					let attachment = this.state.sat.attachments[i];

					if(attachment.type) {
						if(attachment.type === 'signature' && attachment.user_id === this.state.userId) {
							let operationSuccess = await this.removeFile(attachment);

							if(!operationSuccess) return;
						}
					}
				}
			}

			if (!this.state.sat.attachments) this.state.sat.attachments = [];

			const fileUri = await this.uploadFile(photo.uri, 'sat_attatchments');

			if (fileUri && this.state.technicianId && this.state.userId) {

				this.state.sat.attachments.push({ link: fileUri, type: 'signature', date: new Date(), user_id: this.state.userId, name: `Assinatura SAT #${moment().format('DDMMYYYYHHmmss')}`, signatureInfo: this.state.signatureInfo });
				const data = { attachments: this.state.sat.attachments };

				try {
					await Firestore.update(data, 'appointment', this.state.sat.id);

					await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico adicionou assinatura da SAT (App Técnico). Assinada por ${this.state.signatureInfo.name}, ${this.state.signatureInfo.position}`, new Date(), fileUri);

					showMessage({ icon: 'success', message: "Assinatura anexada com sucesso", type: "success" });
				} catch (error) {
					showMessage({ icon: 'error', message: "Erro ao atualizar SAT", type: "error" });
				}
			} else {
				showMessage({ icon: 'error', message: "Ocorreu um erro ao anexar, tente novamente mais tarde.", type: "error" });
				this.setState({ loadingAttachment: false });
			}
		}

		await this.syncSat();
		this.props.navigation.navigate('FinishSat', { sat: this.state.sat });
		this.setState({ loading: false });
	}

	uploadFile = async (uri, path) => {
		let output;

		try {
			const upload = await Storage.uploadImageFromPicker(path, uri);
			const fileUri = await Storage.getFileFromRef(upload);
			output = fileUri;
		} catch (e) {
			showMessage({ icon: 'error', message: "Houve um erro ao anexar a assinatura", type: "error" });
			output = false;
		}

		return output;
	}

	removeFile = async (file) => {
		let output;

		try {
			let newArray = [];

			this.state.sat.attachments.forEach((current, key) => {
				if (current.link !== file.link) {
					newArray.push(current);
				}
			});

			await SATHelper.historyIteration(this.state.sat.id, this.state.userId, 'Técnico removeu assinatura da SAT (App Técnico)', new Date(), file.link);

			let updateData = { attachments : newArray };

			await Firestore.update(updateData, 'appointment', this.state.sat.id);

			await this.syncSat();
			
			output = true;

			showMessage({ icon: 'success', message: "Assinatura anterior sobrescrita com sucesso!", type: "success" });
		} catch (e) {
			output = false;

			showMessage({ icon: 'error', message: "Erro ao sobrescrever assinatura anterior", type: "error" });
		}

		return output;
	}

	renderSignatureCard = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('pen-fancy', 'Assinar Digitalmente')}
				<View style={[styles.card, { height: '90%' }]}>
					<View style={{ width: '100%', height: '100%', backgroundColor: 'gray' }}>
						<CaptureSignature callback={this.saveSignature} />
					</View>
				</View>
			</View>
		);
	}

	renderContent = () => {
		return (
			<View style={styles.container}>
				<View>
					{this.renderSignatureCard()}
				</View>
			</View>

		);
	}

	render() {
		return this.state.loading ? <LoadingOverlay title={'Aguarde, executando operacão...'} /> : this.renderContent();
	}

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	cardWrapper: {
		padding: 10
	},
	card: {
		width: '100%',
		paddingVertical: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 130,
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.tintColor,
		marginTop: 10,
		height: 68,
		width: 68,
		borderRadius: 68,
	},
	image: {
		aspectRatio: 1 / 1,
		height: 64,
		width: 64,
		borderRadius: 64
	},
	technicianBubbleText: {
		textAlign: 'center',
		paddingVertical: 8,
		fontSize: 12,
		color: Colors.text,
		fontWeight: "500",
		paddingHorizontal: 3
	},
	cardHeader: {
		flexDirection: 'row',
		width: '100%',
		marginBottom: 5,
		padding: 5,
		justifyContent: 'flex-start',
		textAlignVertical: 'center'
	},
	cardHeaderText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: Colors.tintColor,
		marginLeft: 5
	},
	cardHeaderIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginRight: 5
	},
	floatingButton: {
		position: 'absolute',
		bottom: 10,
		justifyContent: 'center',
		alignSelf: 'center',
		width: '90%'
	},
});
