import React, { Component } from 'react';
import MapView from 'react-native-maps';
import Colors from '../../constants/Colors';
import { showMessage } from "react-native-flash-message";

import {
	StyleSheet,
	Dimensions,
	View,
	ActivityIndicator,
	TouchableOpacity
} from 'react-native';

import { MaterialIcons } from '@expo/vector-icons';
import GeographicHelper from '../../helper/GeographicHelper';

import * as Location from 'expo-location';
import SessionHelper from '../../helper/SessionHelper';

export default class LocationPicker extends Component {
	state = {
		searchbarList: false,
		loadingLocation: true,
		initialRegion: this.props.initialRegion ? this.props.initialRegion : null,
		region: this.props.region ? this.props.region : { latitude: 0, longitude: 0 },
		address: this.props.address ? this.props.address : null,
	};

	async componentDidMount() {
		if (!this.props.initialRegion) {
			this.getPosition();
		}
	}

	getPosition = async () => {
		this.setState({ loadingLocation: true });

		await GeographicHelper.getCurrentPosition((location) => {
			let latitude = location.lat;
			let longitude = location.lng;

			this.setState({
				initialRegion: {
					latitude,
					longitude,
					latitudeDelta: 0.0143,
					longitudeDelta: 0.0134
				},
				region: {
					latitude,
					longitude
				}
			});

			this.onRegionChangeComplete(this.state.initialRegion);

			this.setState({ loadingLocation: false });
		});

	}

	onRegionChange = (region) => {
		if (region) {
			this.setState({ region });
		}
	};

	onRegionChangeComplete = async (region) => {
		if (region) {
			this.setState({
				region
			});

			this.props.callback({ lat: region.latitude, lng: region.longitude });
		}
	}

	getMapStyle = () => {
		return {
			width: this.props.width ? this.props.width : Dimensions.get('window').width,
			height: this.props.height ? this.props.height : Dimensions.get('window').height
		}
	}

	render() {
		const { initialRegion, region } = this.state;

		return (
			<View style={this.getMapStyle()}>
				{!this.state.loadingLocation ?
					<MapView
						provider={MapView.PROVIDER_GOOGLE}
						style={this.getMapStyle()}
						initialRegion={initialRegion}
						onRegionChange={this.onRegionChange}
						onRegionChangeComplete={this.onRegionChangeComplete}
						ref={ref => this.map = ref}
						loadingEnabled
					>

						<MapView.Marker coordinate={region} />
					</MapView>
					: <ActivityIndicator style={{ marginTop: 80 }} size={'large'}></ActivityIndicator>
				}


				{this.props.locationButton ?
					<TouchableOpacity style={styles.currentLocation} onPress={() => { this.getPosition() }}>
						<View style={styles.button}>
							{this.state.loadingLocation ? <ActivityIndicator style={{ marginTop: 20 }}></ActivityIndicator> : <MaterialIcons name={'my-location'} color={'#fff'} size={32} style={{ marginTop: 15 }} />}
						</View>
					</TouchableOpacity>
					:
					null
				}
			</View>

		);
	}
}


const styles = StyleSheet.create({
	button: {
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		textAlignVertical: 'center'
	},
	currentLocation: {
		position: "absolute",
		right: 10,
		bottom: 10,
		height: 64,
		width: 64,
		borderRadius: 64,
		backgroundColor: Colors.tintColor
	}
});