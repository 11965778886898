import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Image, ActivityIndicator, Platform } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import Colors from '../constants/Colors';
import { ScrollView } from 'react-native-gesture-handler';

export default class Badge extends React.Component {
	state = {
		imageLoaded: false
	}

	getInfoContainer = (label, data) => {
		return (
			<View style={styles.technicianInfoWrapper}>
				<Text style={styles.technicianInfoLabel}>{label}</Text>
				<Text style={styles.technicianInfoText}>{data}</Text>
			</View>
		);
	}

	getImage = () => {
		let output;

		if (this.props.photo) {
			output = (
				<View style={styles.imageWrapper}>
					<Image onLoadEnd={() => { this.setState({ imageLoaded: true }) }} style={styles.image} source={{ uri: this.props.photo }}></Image>
					{!this.state.imageLoaded ? <ActivityIndicator color={'#fafafa'} style={styles.loading}></ActivityIndicator> : null}
				</View>
			);
		} else {
			output = (
				<View style={styles.imageWrapper}>
					<FontAwesome5 solid={true} size={110} name={'user-cog'} color={'white'} />
				</View>
			)
		}

		return output;
	}

	render() {
		return (
			<View style={styles.cardWrapper}>
				<View style={styles.card}>
					{this.getImage()}

					<View style={styles.infoWrapper}>
						<Text style={styles.infoText}>Semil Equipamentos Industriais Ltda.</Text>
						<Text style={styles.infoText}>CNPJ: 80.076.771/0001-00</Text>
					</View>

					<View style={styles.technicianContainer}>
						{this.getInfoContainer('Nome', this.props.name)}
						<View style={{ flexDirection: 'row', justifyContent: 'space-between', maxWidth: 200 }}>
							{this.getInfoContainer('Identificador', this.props.cpf)}
							{this.getInfoContainer('Cargo', 'Técnico')}
						</View>
					</View>


					<View style={styles.logoWrapper}>
						<Image style={styles.logo} source={require('../assets/images/semil-solid-white.png')}></Image>
					</View>
				</View>
			</View>

		);
	}
}

const styles = StyleSheet.create({
	cardWrapper: {
		flex: 1,
		padding: 10,
		minHeight: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		alignContent: 'center',
		justifyContent: 'center',
	},
	card: {
		width: Platform.OS === 'web' ? 400 : '100%',
		borderRadius: 15,
		backgroundColor: '#fff',
		flexDirection: 'column',
		alignItems: 'center',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		height: 'auto',
		maxHeight: '110%',
		shadowOpacity: 0.30,
		shadowRadius: 2.22,
		elevation: 1,
		marginBottom: 20
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.contrastColor,
		marginTop: 50,
		height: 200,
		width: 200,
		borderRadius: 200,
	},
	logoWrapper: {
		width: '100%',
		backgroundColor: Colors.tintColor,
		position: 'absolute',
		alignItems: 'center',
		justifyContent: 'center',
		height: 60,
		borderBottomLeftRadius: 15,
		borderBottomRightRadius: 15,
		bottom: 0,
	},
	logo: {
		aspectRatio: 42 / 11,
		height: 41,
		borderRadius: 5,
		width: 135
	},
	image: {
		aspectRatio: 1 / 1,
		height: 188,
		width: 188,
		borderRadius: 200
	},
	loading: {
		height: 64,
		width: 64,
		bottom: 95,
	},
	infoWrapper: {
		marginVertical: 10,
		alignItems: 'center',
		padding: 5,
		marginBottom: 10,
		marginTop: 15,
	},
	infoText: {
		padding: 2,
		fontSize: 12,
		fontWeight: "500",
		color: Colors.text
	},
	technicianInfoWrapper: {
		padding: 5,
		width: '100%'
	},
	technicianContainer: {
		width: '95%',
		height: 200,
		paddingBottom: 30
	},
	technicianInfoLabel: {
		padding: 2,
		fontSize: 16,
		fontWeight: "bold",
		color: Colors.tintColor
	},
	technicianInfoText: {
		padding: 2,
		fontSize: 20,
		color: Colors.text
	},
});