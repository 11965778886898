import React from 'react';
import { TouchableOpacity, StyleSheet, Text, Image, View } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';

import Colors from '../constants/Colors';

export default class DefaultList extends React.Component {

    render() {

        return (
            <View style={style.container}>
                <TouchableOpacity style={style.list} onPress={() => { this.props.onPress() }}>
                    {this.props.icon ? <FontAwesome5 size={this.props.iconSize ? this.props.iconSize : 20} solid color={!this.props.iconColor ? Colors.text : this.props.iconColor} style={style.icon} name={this.props.icon} /> : null}
                    {this.props.image ? <Image source={this.props.image} style={style.photo}></Image> : null}
                    <Text style={style.text}>{this.props.title}</Text>
                    {this.props.date ? <Text style={style.date}>{this.props.date}</Text> : null}
                </TouchableOpacity>
            </View>
        );
    }
}

const style = StyleSheet.create({
    container : {
        paddingHorizontal: 10, 
        paddingVertical: 5, 
    },
    photo: {
        width: 26,
        height: 26
    },
    date: {
        fontSize: 15,
        color: Colors.text,
        position: 'absolute',
        bottom: 5,
        right: 5
    },
    list: {
        padding: 20,
        width: '100%',
        borderRadius: 5,
        backgroundColor: '#fff',
        flexDirection: 'row',
        alignItems: 'flex-start',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,
        elevation: 1
    },
    text: {
        fontSize: 17,
        marginLeft: 15,
        marginRight: 25,
        color: Colors.text,
        fontWeight: '600'
    },
    icon: {
        fontWeight: 'bold'
    },
    check: {
        fontSize: 20,
        fontWeight: 'bold',
        position: "absolute",
        right: 15,
        top: 20
    }
});