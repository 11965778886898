import * as React from 'react';
import { StyleSheet, Text, View, TouchableOpacity, Switch } from 'react-native';

import { ScrollView } from 'react-native-gesture-handler';
import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";
import moment from 'moment';
import 'moment/locale/pt-br';

import { getNavigationOptions } from '../helper/NavigationHelper';
import SessionHelper from '../helper/SessionHelper';
import SATHelper from '../helper/SATHelper';

import Firestore from '../api/firebase/Firestore';

import Colors from '../constants/Colors';
import ReviewedPartsNames from '../constants/ReviewedPartsNames';

import LoadingOverlay from '../components/LoadingOverlay';
import DefaultInput from '../components/DefaultInput';
import DefaultButton from '../components/DefaultButton';


export default class AddReviewedPartsScreen extends React.Component {
	state = {
		loading: true,
		technicianId: SessionHelper.getTechnician().id,
		userId: SessionHelper.getFirebaseAuth().uid,
		reviewedParts: {
			rolamentos: false,
			mancais: false,
			retentores: false,
			correntes: false,
			correias: false,
			polias: false,
			engrenagens: false,
			rodas_dentadas: false,
			conjunto_pneumatico: false,
			conjunto_hidraulico: false,
			motores: false,
			redutores: false,
			motoredutores: false,
			servomotores: false,
			aperto_de_parafusos: false,
			fitas_lonas: false,
			buchas_geral: false,
			lubrificacao_geral: false,
			sensores_geral: false,
			componentes_eletricos: false
		},
		sat: null,
	}

	async componentDidMount() {
		this.setState({ loading: true });

		const { navigation } = this.props;
		navigation.setParams({
			saveInfo: this.saveInfo
		});

		await this.setNavigationData(this.props.navigation.state.params);

		await this.syncSat();

		this.setState({ loading: false });
	}

	setNavigationData = async (params) => {
		await this.setState({ sat: params.sat });
	}

	syncSat = async () => {
		const query = await Firestore.getDoc('appointment', this.state.sat.id);

		let sat = query.data();
		sat.id = query.id;

		if (this.state.sat.reviewedParts) {
			this.setState({ reviewedParts: sat.reviewedParts, sat });
		} else {
			this.setState({ sat });
		}
	}

	static navigationOptions = ({ navigation }) => {
		const { params = {} } = navigation.state;

		return getNavigationOptions(`Revisar Peças ${navigation.state.params.sat.title.replace('Assistência ', '')}`,
			<View style={{ flexDirection: 'row' }}>
				<TouchableOpacity onPress={() => { params.saveInfo() }}>
					<FontAwesome5 color={'white'} style={{ paddingHorizontal: 10 }} size={22} name={'check'} />
				</TouchableOpacity>
			</View>
		);
	};

	handleInputChange(value, field) {
		let inputs = this.state.reviewedParts;

		if (inputs.hasOwnProperty(field)) {
			inputs[field] = value;
			this.setState({ infoInputs: inputs });
		}
	}

	renderForm = () => {
		return (
			<View style={styles.container}>
				<ScrollView style={{ width: '100%' }}>
					<View style={{ alignItems: 'center' }}>
						<View style={{ alignItems: 'flex-start', maxWidth: '90%' }}>
							<Text style={{ paddingHorizontal: 10, paddingTop: 10, fontSize: 16, color: Colors.text, fontWeight: '600', }}>Marque as peças que foram revisadas!</Text>
							<Text style={{ paddingHorizontal: 10, paddingTop: 10, fontSize: 14, color: Colors.contrastColor, fontWeight: '600', }}>Lembre-se que por essa ser uma SAT que pode ter mais de um técnico algumas peças podem já estar marcadas ou serem marcadas por outros técnicos!</Text>
						</View>

						<View style={[styles.formContainer, { paddingVertical: 20 }]}>
							{
								Object.keys(this.state.reviewedParts).sort().map((key) => {
									return (
										<View style={{ flexDirection: 'row', padding: 15, borderColor: Colors.contrastColor, borderBottomWidth: .6 }}>
											<Text style={{ width: '80%', color: Colors.text, fontSize: 16, fontWeight: '400', marginTop: 5 }}>{ReviewedPartsNames[key]}</Text>
											<Switch
												style={{ width: '20%' }}
												value={this.state.reviewedParts[key]}
												onValueChange={v => { this.handleInputChange(v, key) }}
											/>
										</View>
									);
								})
							}

						</View>

					</View>
				</ScrollView>
			</View>
		)
	}

	saveInfo = async () => {
		try {
			this.setState({ loading: true });

			const reviewedParts = this.state.reviewedParts;

			if(reviewedParts) {
				await Firestore.update({ reviewedParts: this.state.reviewedParts },
					'appointment', this.state.sat.id)
	
				await SATHelper.historyIteration(this.state.sat.id, this.state.userId, `Técnico atualizou as peças revisadas`, new Date());
			}

			showMessage({
				icon: 'success',
				message: 'Atualização de peças revisadas com suceso!',
				type: "success",
			});

			this.props.navigation.goBack();

			this.setState({ loading: false });

		} catch (error) {
			showMessage({
				icon: 'danger',
				message: 'Erro ao atualizar',
				type: "danger",
			});

			this.setState({ loading: false });
		}
	}

	render() {
		return this.state.loading ? <LoadingOverlay title={'Aguarde, executando operacão...'} /> : this.renderForm();
	}

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	cardWrapper: {
		padding: 10
	},
	card: {
		width: '100%',
		paddingVertical: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 130,
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.tintColor,
		marginTop: 10,
		height: 68,
		width: 68,
		borderRadius: 68,
	},
	image: {
		aspectRatio: 1 / 1,
		height: 64,
		width: 64,
		borderRadius: 64
	},
	technicianBubbleText: {
		textAlign: 'center',
		paddingVertical: 8,
		fontSize: 12,
		color: Colors.text,
		fontWeight: "500",
		paddingHorizontal: 3
	},
	cardHeader: {
		flexDirection: 'row',
		width: '100%',
		marginBottom: 5,
		padding: 5,
		justifyContent: 'flex-start',
		textAlignVertical: 'center'
	},
	cardHeaderText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: Colors.tintColor,
		marginLeft: 5
	},
	cardHeaderIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginRight: 5
	},
	floatingButton: {
		position: 'absolute',
		bottom: 10,
		justifyContent: 'center',
		alignSelf: 'center',
		width: '90%'
	},
	formContainer: {
		width: '90%',
		height: 'auto'
	},
});
