import { decode, encode } from 'base-64'
if (!global.btoa) { global.btoa = encode }
if (!global.atob) { global.atob = decode }
import FlashMessage from "react-native-flash-message";
import React from 'react';
import { Platform, StatusBar, StyleSheet, View, Permissions, LogBox } from 'react-native';
import Colors from './constants/Colors';
import FirebaseProduction from './constants/Firebase';
import FirebaseTest from './constants/FirebaseTest';
import * as firebase from 'firebase';
import AppNavigator from './navigation/AppNavigator';
import Constants from 'expo-constants';

// For recording videos or showcase
// LogBox.ignoreAllLogs(true);
export default class App extends React.Component {

	state = {
		loadedSession: false
	}

	async UNSAFE_componentWillMount() {
		if (!firebase.apps.length) {
			const firebaseConfig = Constants.appOwnership != 'expo' ? FirebaseProduction : FirebaseTest;
			firebase.initializeApp(firebaseConfig);
		}
	}

	render() {
		return (
			<View style={styles.container}>
				{Platform.OS === 'ios' && <StatusBar barStyle="light-content" />}
				{Platform.OS === 'android' && <StatusBar barStyle="light-content" backgroundColor={Colors.tintColor} />}
				<AppNavigator />
				<FlashMessage position="top" />
			</View>
		)
	}
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor,
	},
});
