import * as Notifications from 'expo-notifications'
import Colors from '../constants/Colors'
import Firestore from '../api/firebase/Firestore';
import SessionHelper from '../helper/SessionHelper';

export default class NotificationHelper {
    constructor() {
        this.badgeCount;
    }

    static async checkPermission() {

        const { status: existingStatus } = await Notifications.getPermissionsAsync();

        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }

        return finalStatus;
    }

    static getAPIEndpoint() {
        return 'https://exp.host/--/api/v2/push/send';
    }

    static getBadgeCount() {
        return this.badgeCount;
    }

    static setBadgeCount(value) {
        this.badgeCount = value;
    }

    static async setBadge(clear = false) {
        try {
            let counter = 0;

            if (!clear) {
                let query = await Firestore.customQuery('notification').where('id_user', '==', SessionHelper.getFirebaseAuth().uid).where('viewed', '==', false).limit(10).get();
                counter = query.docs.length;
            }

            this.setBadgeCount(counter);
            await Notifications.setBadgeCountAsync(counter);
        } catch (e) {
            console.log(e);
            return;
        }
    }

    static getRequestHeaders() {
        return {
            'Accept-encoding': 'gzip, deflate',
            'Content-Type': 'application/json'
        };
    }

    static async mountRequestBody(token, data, notificationData) {
        let badgeCounter = await this.getUserBadgeCounter(data.id_user);

        return JSON.stringify({
            to: token,
            title: data.title,
            body: data.body,
            badge: badgeCounter,
            data: notificationData,
            priority: 'normal',
            sound: 'default'
        });
    }

    static async getToken() {
        return await Notifications.getExpoPushTokenAsync();
    }

    static async add(data, sendPush = false, token, notificationData = {}) {
        //Should add a new notif to user, send push to device if asked, and increase badge count on tabs and menus;

        try {

            data.viewed = false;
            notificationData.viewed = false;
            
            await Firestore.insert(data, 'notification');

            if (sendPush && token) {
                let requestBody = await this.mountRequestBody(token, data, notificationData);

                await fetch(this.getAPIEndpoint(), {
                    method: 'POST',
                    headers: this.getRequestHeaders(),
                    body: requestBody,
                });
            }

        } catch (error) {

            console.log('Notif Error: ' + error);
        }


    }

    static async checkPermission() {

        const { status: existingStatus } = await Notifications.getPermissionsAsync();

        let finalStatus = existingStatus;

        if (existingStatus !== 'granted') {
            const { status } = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }

        return finalStatus;
    }

    static getLocalNotification(title, body) {
        return {
            title: title,
            body: body,
            ios: {
                sound: true
            },
            android:
            {
                sound: true,
                icon: 'https://firebasestorage.googleapis.com/v0/b/booker-8cfb9.appspot.com/o/notification%2Fnotification-icon-android.png?alt=media&token=021c1330-21de-45ff-b9ce-57491bcc2e30',
                color: Colors.tintColor,
                priority: 'high',
                sticky: false,
                vibrate: true
            }
        };
    }

    //You must save the id that will resolve from the output, so you can cancel the scheduling
    static async scheduleLocalNotfification(notification, time, repeat) {
        let schedulingOptions = {
            time: time
        };

        //(optional) (string) -- 'minute', 'hour', 'day', 'week', 'month', or 'year'.
        if (repeat) schedulingOptions.repeat = repeat;

        return await Notifications.scheduleLocalNotificationAsync(notification, schedulingOptions);
    }

    static async cancelLocalNotification(localNotificationId) {
        await Notifications.cancelScheduledNotificationAsync(localNotificationId);
    }

    static async getUserBadgeCounter(id_user) {
        if (id_user) {
            const query = await Firestore.customQuery('notification').where('id_user', '==', id_user).where('viewed', '==', false).get();
            const counter = query.docs.length;

            return counter + 1;
        } else {
            return 1;
        }
    }
}