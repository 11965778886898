import React from 'react';
import {
    StyleSheet,
    Text,
    View,
    ScrollView,
    ActivityIndicator,
    RefreshControl,
    Image
} from 'react-native';
import NotificationList from '../components/NotificationList';
import NotificationHelper from '../helper/NotificationHelper';
import Firestore from '../api/firebase/Firestore';
import Colors from '../constants/Colors';
import SessionHelper from '../helper/SessionHelper';
import { getNavigationOptions, toggleStatusBarStyle } from '../helper/NavigationHelper';
import moment from 'moment';

export default class NotificationScreen extends React.Component {

    state = {
        docs: [],
        fetched: false,
        reloading: false,
    }

    async getDocs() {

        let query = await Firestore.getCollection('notification', ['user_id', '==', SessionHelper.getFirebaseAuth().uid], ['created_at', 'desc']);
        let docs = [];
        let badgeCount = NotificationHelper.getBadgeCount();

        query.forEach((doc, key) => {
            docs.push(doc.data());

            if (!doc.data().viewed) {
                doc.ref.update({ viewed: true });
                badgeCount--;
            }
        });

        this.setState({ docs: docs, fetched: true, reloading: false });
    }

    async componentDidMount() {
		toggleStatusBarStyle(this, 'light-content');
		
		this.willFocusSubscription = this.props.navigation.addListener(
			'willFocus',
			async () => {
			}
		);

        this.getDocs();
	}
	
	componentWillUnmount() {
		this.willFocusSubscription.remove();
	}

    formatDate(date) {
        let formattedDate = moment(date.toDate());

        return formattedDate.format('DD/MM/YYYY HH:mm:ss');
    }

    renderNotifications() {

        let notifications = this.state.docs.map((element, key) => {
            return <NotificationList key={key} navigation={this.props.navigation} data={element.data} navigateScreen={element.navigateScreen} date={this.formatDate(element.created_at)} body={element.body} title={element.title} icon={'cog'} iconColor={Colors.contrastColor}/>
        });

        return <View style={{ alignItems: 'center' }}>{ notifications }</View>
    }

    renderEmptyNotifications() {
        return (
            <View style={{
                marginTop: 60,
                alignContent: 'center',
                marginBottom: 20
            }}>
                <Image
                    style={{
                        alignSelf: 'center',
                        height: 200,
                        width: 200,
                        marginBottom: 20
                    }}
                    source={require('../assets/images/notification.png')}
                />
                <Text style={{
                    justifyContent: "center",
                    textAlign: "center",
                    color: Colors.regularText,
                    padding: 20
                }}>Você não ainda não tem novas notificações, confrome você for adicionado a SAT's você será notificado.</Text>
            </View>
        );
    }

    renderLoading() {
        return <ActivityIndicator style={{ alignSelf: 'center', marginTop: '50%' }}/>
    }

    render() {
        return (
            <View style={styles.container}>
                <ScrollView refreshControl={ <RefreshControl refreshing={this.state.reloading} onRefresh={() => { this.getDocs() }} /> }>
                    { !this.state.fetched ? this.renderLoading() : null }
                    { !this.state.docs.length && this.state.fetched ? this.renderEmptyNotifications() : this.renderNotifications() }
                </ScrollView>
            </View>
        );
    }
}


NotificationScreen.navigationOptions = getNavigationOptions('Notificações');

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    emptyText: {
        alignSelf: 'center',
        marginTop: '50%',
        color: 'grey',
    }
});
