import { createAppContainer, createSwitchNavigator } from 'react-navigation';

import Tab from './BottomTabNavigator';
import AuthNavigator from './AuthNavigator';

import LoginScreen from '../screens/LoginScreen';
import ForgotPasswordScreen from '../screens/ForgotPasswordScreen';
// import RegisterScreen from '../screens/RegisterScreen';

const switchConfig = {
    initialRouteName: 'AuthNavigator',
}

const navigator = createSwitchNavigator({
    AuthNavigator: AuthNavigator,
    LoginNavigator: LoginScreen,
    ForgotPasswordNavigator: ForgotPasswordScreen,
    Root: Tab,
  }, switchConfig)

export default createAppContainer(navigator);