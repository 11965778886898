import React from 'react';
import Colors from '../constants/Colors'
import {
	StyleSheet,
	Text,
	View,
	Image,
	StatusBar,
	Platform,
} from 'react-native';

import { showMessage } from "react-native-flash-message";

import DefaultButton from '../components/DefaultButton';
import DefaultInput from '../components/DefaultInput';

import Auth from './../api/firebase/Auth';

export default class ForgotPasswordScreen extends React.Component {

	state = {
		loading: false,
		email: '',
		errorMessage: ''
	}

	async componentDidMount() {
		StatusBar.setBarStyle('light-content');
	}

	async resetPassword() {
		if (this.state.email.length < 4) {
			this.setState({ errorMessage: "Preencha seu email para redefinir a senha." });
			return;
		}

		try {
			await Auth.resetPassword(this.state.email, this.successResetEmail, this.errorResetEmail);

			showMessage({
				icon: 'success',
				message: "Email enviado com sucesso, cheque sua caixa de entrada",
				type: "success",
			});

			this.props.navigation.navigate('LoginNavigator');

		} catch (error) {
			const errorMessage = error.toString();
			if (errorMessage === "Error: There is no user record corresponding to this identifier. The user may have been deleted.") {
				this.setState({ errorMessage: `O usuário ${this.state.email} não existe.` })
			} else {
				this.setState({ errorMessage: "Ocorreu um erro ao enviar o email :(" })
			}
		}
	}

	render() {
		return (
			<View style={style.container}>

				<View style={style.loginContainer}>
					<View style={{ alignItems: 'center', marginBottom: 10 }}>
						<Image style={style.logo} source={require('../assets/images/logo.png')}></Image>
					</View>

					<Text style={style.title}>Digite o seu email cadastrado para receber o link de recuperação de senha.</Text>

					<DefaultInput
						ref={(input) => { this.passwordInput = input; }}
						value={this.state.email}
						onChangeText={(value) => { this.setState({ email: value }) }}
						placeholder={'Digite o email'}
						returnKeyType={'done'}
						autoCapitalize={'none'}
						autoCorrect={false}
					/>

					{this.state.errorMessage ? <Text style={style.errorMessage}>{this.state.errorMessage}</Text> : null}

					<View style={{ marginTop: 15 }}>
						<DefaultButton title={"RECUPERAR"} loading={this.state.loading} onPress={() => { this.resetPassword() }} />
					</View>
					<View style={{ marginTop: 5 }}>
						<DefaultButton title={"CANCELAR"} color={Colors.secondaryTintColor} onPress={() => {
							this.props.navigation.navigate('LoginNavigator');
						}} />
					</View>
				</View>

			</View>
		)
	}
}

const style = StyleSheet.create({

	container: {
		flex: 1,
		backgroundColor: Colors.tintColor,
		justifyContent: 'center',
		alignItems: 'center'
	},
	loginContainer: {
		marginBottom: 60,
		backgroundColor: Colors.backgroundColor,
		width: Platform.OS === 'web' ? 450 : '90%',
		borderRadius: 25,
		padding: 20
	},
	logo: {
        margin: 15,
        height: 56,
        borderRadius: 5,
		width: 250,
		height: 76
    },
	title: {
		color: Colors.text,
		fontSize: 16,
		padding: 10
	},
	errorMessage: {
		color: 'red',
		padding: 10,
		alignSelf: 'center',
		justifyContent: 'center'
    },
})