import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Image } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import {
	Placeholder,
	PlaceholderMedia,
	PlaceholderLine,
	Shine
} from "rn-placeholder";

import Colors from '../constants/Colors';

//React native calendar
import moment from 'moment';
import 'moment/locale/pt-br';
import SessionHelper from '../helper/SessionHelper';

export default class HourCard extends React.Component {
	async navigateToSat() {
		this.props.navigation.navigate('Sat', { sat: { ...this.props.item.appointment, id: this.props.item.appointment.id }, technicianAppointment: SessionHelper.getTechnician().id })
	}

	render() {
		return (
			<View style={style.cardWrapper}>
				<View style={style.card}>
					<View>
						<Text style={{ fontSize: 18, fontWeight: 'bold' }}>{moment(this.props.item.startDate.toDate()).format('DD/MM [-] dddd')}</Text>
						<Text style={{ fontSize: 16, fontWeight: 'bold', color: Colors.contrastColor, marginTop: 8 }}>Início: {moment(this.props.item.startDate.toDate()).format('DD/MM/YYYY HH:mm')}</Text>
						{this.props.item.endDate ?
							<Text style={{ fontSize: 16, fontWeight: 'bold', color: Colors.tintColor }}>Fim: {this.props.item.endDate ? moment(this.props.item.endDate.toDate()).format('DD/MM/YYYY HH:mm') : `Não apontado`}</Text>
							: <Text style={{ fontSize: 16, fontWeight: 'bold', color: Colors.secondaryTintColor }}>Fim: Não apontado</Text>
						}

						{this.props.item.endDate && this.props.item.minutesWorked ?
							<Text style={{ fontSize: 12, color: Colors.disabled, marginTop: 1 }}>Total: {this.props.item.minutesWorked} minutos</Text>
							: null}

						<Text style={{ fontSize: 14, color: Colors.disabled, marginTop: 8 }}>{this.props.item.appointment.title}</Text>
					</View>
					<View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', position: 'absolute', right: 0, height: '100%', marginTop: 5, padding: 8 }}>
						<TouchableOpacity style={style.actionButton} onPress={() => { this.navigateToSat() }} >
							<FontAwesome5 style={{ marginHorintal: 10, fontSize: 18, color: `#fff` }} name={'tools'} solid />
						</TouchableOpacity>
						{!this.props.item.endDate ?
							<TouchableOpacity style={[style.actionButton, { backgroundColor: Colors.tintColor }]} onPress={() => { this.props.onHourPress() }} >
								<FontAwesome5 style={{ marginHorintal: 10, fontSize: 18, color: `#fff` }} name={'clock'} solid />
							</TouchableOpacity> : null}
						{this.props.item.appointment.status == "open" ?
							<TouchableOpacity style={[style.actionButton, { backgroundColor: Colors.secondaryTintColor }]} onPress={() => { this.props.onDeletePress() }} >
								<FontAwesome5 style={{ marginHorintal: 10, fontSize: 18, color: `#fff` }} name={'trash'} solid />
							</TouchableOpacity>
							: null}
					</View>
				</View>
			</View>

		);
	}
}

const style = StyleSheet.create({
	actionButton: {
		height: 48, width: 48, borderRadius: 24, justifyContent: 'center', backgroundColor: Colors.contrastColor, alignItems: 'center', marginHorizontal: 4
	},
	cardWrapper: {
		paddingHorizontal: 8,
		paddingVertical: 4
	},
	card: {
		flex: 1,
		width: '100%',
		flexDirection: 'row',
		padding: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		alignItems: 'flex-start',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 80,
		maxHeight: 160,
	},
});