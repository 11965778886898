import * as React from 'react';
import { StyleSheet, Text, View, Image, StatusBar, ActivityIndicator } from 'react-native';
import Auth from '../api/firebase/Auth';
import Colors from '../constants/Colors';
import Modal from '../components/Modal';
import DefaultButton from '../components/DefaultButton';
import { getNavigationOptions } from '../helper/NavigationHelper';
import DefaultList from '../components/DefaultList';
import SessionHelper from '../helper/SessionHelper';

export default class AccountScreen extends React.Component {
	state = {
		technician: SessionHelper.getTechnician(),
		aboutModal: false,
		imageLoaded: false
	}

	async componentDidMount() {
		StatusBar.setBarStyle('light-content');
	}

	render() {
		return (
			<View style={styles.container}>
				{this.userInfo()}
				<DefaultList onPress={() => { this.setState({ aboutModal: true }) }} icon={'info-circle'} title={'Informações do App'} iconColor={Colors.contrastColor} />
				<DefaultList onPress={() => { Auth.logout() }} icon={'sign-out-alt'} title={'Sair'} iconColor={Colors.contrastColor} />
                <Modal onClose={() => { this.setState({ aboutModal: false }) }} animation={'slide'} visible={this.state.aboutModal} content={this.aboutModal()}/>
			</View>
		);
	}

	aboutModal() {

        return (
            <View style={{alignItems: 'center', paddingHorizontal: 25, marginTop: 5, backgroundColor: '#fafafa', borderRadius: 5}}>
                <Text style={{paddingHorizontal: 15, paddingTop: 15, fontWeight: 'bold', fontSize: 22, color: Colors.tintColor}}>{'Semil Connect'}</Text>
                <Text style={{paddingHorizontal: 15, paddingTop: 2, paddingBottom: 15, fontWeight: 'bold', fontSize: 17, color: Colors.contrastColor}}>{'App do Técnico'}</Text>
                
                <View style={{ shadowColor: "#000", shadowOffset: {width: 0,height: 4,}, shadowOpacity: 0.30, shadowRadius: 4.65, elevation: 8,}}>
                    <Image style={{width: 125, height: 125, borderRadius: 15}} source={require('../assets/images/icon.png')}/>
                </View>
                
                <Text style={{paddingTop: 25, color: Colors.title, fontWeight: 'bold'}}>{'Versão 1.0.0'}</Text>
                <Text style={{paddingTop: 25, color: 'grey'}}>{'Desenvolvido por EasyData Softwares Ltda.'}</Text>
                <Text style={{paddingTop: 5, color: 'grey'}}>{'contato@easydata.net.br'}</Text>
                <Text style={{paddingTop: 5, color: 'grey'}}>{'CNPJ 35.698.234/0001-39'}</Text>

                <View style={{paddingTop: 40}}/>
                	<DefaultButton width={'100%'} color={Colors.contrastColor} icon={'arrow-down'} title={'Dispensar'} onPress={() => { this.setState({ aboutModal: false }) }}/>
                <View style={{paddingTop: 40}}/>

            </View>
        )
	}
	
	getProfilePicture() {
        return this.state.technician.photo ? {uri: this.state.technician.photo} : require('../assets/images/defaultProfile.png');
    }

	userInfo() {
		return (
			<View style={styles.userInfo}>
				{ !this.state.imageLoaded && <ActivityIndicator color={Colors.tintColor} style={{position: 'absolute', left: 42, top: 27}} size={'small'}/> }
                <Image
                    style={styles.userImage}
                    source={this.getProfilePicture()}
                    onLoadEnd={() => {this.setState({ imageLoaded: true })}}
                />
				<View style={{ flexDirection: 'column' }}>
					<Text style={{ color: Colors.title, fontWeight: 'bold', fontSize: 16 }}>{this.state.technician.name}</Text>
					<Text style={{ color: Colors.contrastColor, fontWeight: '600', fontSize: 14 }}>{this.state.technician.cpf}</Text>
				</View>
			</View>
		)
	}
}

AccountScreen.navigationOptions = getNavigationOptions('Minha Conta');

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: '#fafafa',
		paddingTop: 15,
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	userImage: {
        borderRadius: 32,
        height: 64,
		width: 64,
		marginLeft: 15,
		top: -15,
		left: -15,
		borderColor: Colors.contrastColor,
		borderWidth: 2
    },
});
