import * as firebase from 'firebase';
import { Platform } from 'react-native';
import uuid from 'uuid/v4';

export default class Storage {

	static async uploadImageFromPicker(folder, fullPath) {
		if (Platform.OS == 'web') {
			const ext = fullPath.split(';')[0].split('/')[1];
			const fileName = `${uuid()}.${ext}`;
			const refFile = `${folder}/${fileName}`;

			await firebase.storage().ref(folder).child(fileName).putString(fullPath.split(',')[1], 'base64', { contentType: 'image/png' });

			return refFile;
		} else {
			const ext = fullPath.split('.').pop();
			const fileName = `${uuid()}.${ext}`;

			const response = await fetch(fullPath);
			const blob = await response.blob();

			const refFile = `${folder}/${fileName}`;

			await firebase.storage().ref(refFile).put(blob);

			return refFile;
		}
	}

	static async getFileFromRef(ref) {
		return await firebase.storage().ref(ref).getDownloadURL();
	}

	static async removeFile(ref) {

		try {

			return await firebase.storage().refFromURL(ref).delete();

		} catch (error) {

			return error;
		}
	}
}