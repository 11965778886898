import * as firebase from 'firebase';
import SessionHelper from './SessionHelper';
import { Linking, Platform } from 'react-native';
import * as Location from 'expo-location';
import { showMessage } from 'react-native-flash-message';
import ipify from 'react-native-ipify';
import Firestore from '../api/firebase/Firestore';
export default class GeographicHelper {
	static async getCurrentPosition(callback) {
		await Location.requestForegroundPermissionsAsync();

		const { status } = await Location.getForegroundPermissionsAsync();

		if (status && status == 'granted') {
			try {
				let location = await Location.getCurrentPositionAsync({ accuracy: Location.Accuracy.Low });

				if (location && location.coords && location.coords.latitude && location.coords.longitude) {
					let coords = {
						lat: location.coords.latitude,
						lng: location.coords.longitude
					};

					SessionHelper.setLocation(coords);
					callback(coords);
				} else {
					throw new Error('GPS location couldnt be found');
				}
			} catch (e) {
				let lastLocation = await Location.getLastKnownPositionAsync();

				if (lastLocation && lastLocation.coords && lastLocation.coords.latitude && lastLocation.coords.longitude) {
					let coords = {
						lat: lastLocation.coords.latitude,
						lng: lastLocation.coords.longitude
					};

					SessionHelper.setLocation(coords);
					callback(coords);
				}

				let location = await this.getLocationViaIp();

				callback(location);

				console.log(e);
			}

		} else {
			showMessage({
				message: 'Permissões de localização recusadas, ative para usar funções que usam o GPS',
				type: 'warning',
				icon: 'warning',
				onPress: async () => { await Location.requestForegroundPermissionsAsync() }
			});

			let location = await this.getLocationViaIp();
			callback(location);
		}
	}

	static async recordTechnicianGeodata(locationObject) {
		try {
			const geoPoint = GeographicHelper.setFirebaseGeopoint(locationObject[0].coords.latitude, locationObject[0].coords.longitude);
			const speed = locationObject[0].coords.speed && locationObject[0].coords.speed > 0 ?
				GeographicHelper.epsilonRound(parseFloat(locationObject[0].coords.speed) * 3.6) : 0;

			const user_id = SessionHelper.getFirebaseAuth().uid;

			if (user_id && geoPoint) {
				const log = {
					coordinates: geoPoint,
					speed: speed,
					os: Platform.OS,
					date: new Date(),
					user_id: user_id
				}

				await Firestore.insert(log, 'location_log');
			}
		} catch (error) {
			return;
		}
	}

	static async getLocationViaIp() {
		try {
			if (SessionHelper.getLocation() && SessionHelper.getLocation().lat && SessionHelper.getLocation().lng) return SessionHelper.getLocation();

			showMessage({
				message: 'Localização de baixa precisão sendo utlizada',
				type: 'info',
				icon: 'info'
			});

			const ip = await ipify();

			if (ip) {
				const req = await fetch(`http://ip-api.com/json/${ip}`, {
					method: 'GET',
					headers: {
						'content-type': 'application/json'
					}
				});

				let response = await req.json();

				if (response && response.status == 'success') {
					if (response.lat && response.lon) {
						let location = {
							lat: response.lat,
							lng: response.lon
						};

						SessionHelper.setLocation(location);
						return location;
					}
				}
			}
		} catch (e) {
			return {
				lat: -27.105235,
				lng: -52.613632
			}
		}
	}

	static getDistanceBetweenPoints(start, end) {
		const R = 6371;

		let dLat = (end.lat - start.lat) * Math.PI / 180;
		let dLng = (end.lng - start.lng) * Math.PI / 180;

		let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(start.lat * Math.PI / 180) * Math.cos(end.lat * Math.PI / 180) *
			Math.sin(dLng / 2) * Math.sin(dLng / 2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c;

		let distanceObject = {};

		if (d < 1) {
			distanceObject = {
				value: parseInt(this.kmToMeters(d)),
				unit: 'm'
			}
		} else {
			distanceObject = {
				value: this.epsilonRound(d),
				unit: 'km'
			}
		}

		return distanceObject;
	}

	static getRawDistanceBetweenPoints(start, end) {
		const R = 6371;

		let dLat = (end.lat - start.lat) * Math.PI / 180;
		let dLng = (end.lng - start.lng) * Math.PI / 180;

		let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(start.lat * Math.PI / 180) * Math.cos(end.lat * Math.PI / 180) *
			Math.sin(dLng / 2) * Math.sin(dLng / 2);
		let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		let d = R * c;

		return this.epsilonRound(d);
	}

	static epsilonRound(value) {
		return Math.round(((parseFloat(value)) + Number.EPSILON) * 100) / 100;
	}

	static kmToMeters(km) {
		return this.epsilonRound(parseFloat(km * 1000))
	}

	static metersToKm(meters) {
		return this.epsilonRound(parseFloat(meters / 1000))
	}

	static setFirebaseGeopoint(lat, lng) {
		return new firebase.firestore.GeoPoint(lat, lng);
	}

	static getLatLngFromGeopoint(geoPoint) {
		return {
			lat: geoPoint.latitude,
			lng: geoPoint.longitude
		}
	}

	static async setSessionLocation() {
		GeographicHelper.getCurrentPosition(async (location) => {
			SessionHelper.setLocation(location);
		});
	}

	static async openMap(coords, label) {
		const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });

		if (!label) label = `Localização provida por ACE`;

		const url = Platform.select({
			ios: `${scheme}${label}@${coords}`,
			android: `${scheme}${coords}(${label})`
		});

		Linking.openURL(url);
	}
}