import * as ImagePicker from 'expo-image-picker';

export default class MediaPicker {
	static pickMedia = async (callback, instance, aspect) => {
		const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();

		if (status === 'granted') {

			let result = await ImagePicker.launchImageLibraryAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images,
				allowsEditing: true,
				aspect: aspect ? aspect : [4, 3],
				allowsEditing: true,
				quality: 0.4
			});

			if (!result.cancelled) {
				callback(result, instance);
			}
		}
	}

	static cameraPicker = async (callback, instance) => {
		const { status } = await ImagePicker.requestCameraPermissionsAsync();
		const rollStatus = await ImagePicker.requestMediaLibraryPermissionsAsync();

		if (status === 'granted' && rollStatus.status === 'granted') {

			let result = await ImagePicker.launchCameraAsync({
				mediaTypes: ImagePicker.MediaTypeOptions.Images,
				allowsEditing: true,
				aspect: [4, 3],
				quality: 0.4
			});

			if (!result.cancelled) {
				callback(result, instance);
			}
		}
	}
}
