import React from 'react';
import { View, StyleSheet, Text, ActivityIndicator, Image } from 'react-native';
import { BlurView } from 'expo-blur';
import Colors from '../constants/Colors';
import LoadingOverlay from './LoadingOverlay';

export default class SplashLoading extends React.Component {

	render() {

		return (
			<LoadingOverlay title={'Inicializando...'}/>
		);
	}
}

const styles = StyleSheet.create({
	loading: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		alignItems: 'center',
		justifyContent: 'center'
	},
	text: {
		marginTop: 10,
		color: Colors.tintColor
	},
	image: {
		marginTop: -20,
		marginBottom: 20,
		height: 96,
		width: 96,
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
	}
});