import React from 'react';
import { TextInput, Text, View, StyleSheet } from 'react-native';

import Colors from '../constants/Colors';

export default class DefaultInput extends React.Component {

    state = {
        value: this.props.value ? this.props.value : '',
        isFocused: false,
        errorMessage: '',
        isValid: true,
    }

    handleFocus = event => {
        this.setState({ isFocused: true });

        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    }

    handleBlur = event => {

        this.setState({ isFocused: false });

        // this.validate();

        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    }

    handleTextChange = async event => {

        await this.setState({ value: event })

        this.validate();

        this.props.onChangeText(event, this.props.field);
    }

    isValid() {
        return this.state.isValid;
    }

    validate() {

        let value = this.state.value;
        let errorMessage = '';
        let isValid = true;

        if (this.props.email) {

            if (!this.validateEmail(value)) {

                errorMessage = 'E-mail inválido.';
            }
        }

        if (this.props.minLength) {

            if (value.length < this.props.minLength) {

                errorMessage = `Informe ao menos ${this.props.minLength} caracteres.`;
            }
        }

        if (this.props.maxLength) {

            if (value.length > this.props.maxLength) {

                errorMessage = `É permitido até ${this.props.minLength} caracteres.`;
            }
        }

        if (this.props.cpf) {

            if (!this.validateCPF(value)) {

                errorMessage = 'Cpf inválido.';
            }
        }

        if (this.props.cnpj) {

            if (!this.validateCNPJ(value)) {

                errorMessage = 'Cnpj inválido.';
            }
        }

        if (this.props.number) {

            let regex = /^\d+$/;

            if (!regex.test(value)) {
                errorMessage = 'É permitido apenas números.'
            }
        }

        if (this.props.cardNumber) {
            if (!this.validateCardNumber(value)) {
                errorMessage = 'Cartão inválido.';
            }
        }

        if (this.props.cardExpirity) {

            let regex = /^\d{2}\/\d{2}$/g;

            if (!regex.test(value)) {
                errorMessage = 'Data de validade inválida.';
            }
        }

        if (this.props.required) {
            if (value === '') {
                errorMessage = 'Campo obrigatório.';
            }
        }

        if (errorMessage !== '') {
            isValid = false;
        }

        if (this.props.onValidate) {
            this.props.onValidate(isValid);
        }

        this.setState({ errorMessage: errorMessage, isValid: isValid });
    }

    validateCPF(cpf) {
        let soma = 0;
        let resto;

        let regex = /[.,-/\s]/g;
        cpf = cpf.replace(regex, '');

        if (cpf == '00000000000') return false;
        for (i = 1; i <= 9; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11)) resto = 0;
        if (resto != parseInt(cpf.substring(9, 10))) return false;

        soma = 0;
        for (i = 1; i <= 10; i++) soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
        resto = (soma * 10) % 11;

        if ((resto == 10) || (resto == 11)) resto = 0;
        if (resto != parseInt(cpf.substring(10, 11))) return false;

        return true;
    }

    validateCardNumber(card) {

        let regex = /[.,-/\s]/g;
        card = card.replace(regex, '');

        var s = 0;
        var doubleDigit = false;
        for (var i = card.length - 1; i >= 0; i--) {
            var digit = +card[i];
            if (doubleDigit) {
                digit *= 2;
                if (digit > 9)
                    digit -= 9;
            }
            s += digit;
            doubleDigit = !doubleDigit;
        }

        return s % 10 == 0;
    }

    validateCNPJ(cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');

        if (cnpj == '') return false;

        if (cnpj.length != 14)
            return false;

        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false;


        tamanho = cnpj.length - 2
        numeros = cnpj.substring(0, tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0)) return false;
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;

        return true;
    }

    validateEmail(email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    render() {

        return (
            <View pointerEvents={this.props.events} style={this.props.style}>
                <TextInput
                    ref={(input) => { this.inputRef = input; }}
                    value={this.props.value}
                    keyboardType={this.props.keyboardType}
                    onChangeText={this.handleTextChange}
                    secureTextEntry={this.props.secureTextEntry}
                    autoCapitalize={this.props.autoCapitalize}
                    autoCorrect={this.props.autoCorrect}
                    placeholder={this.props.placeholder}
                    selectionColor={Colors.tintColor}
                    onFocus={this.handleFocus}
                    returnKeyType={this.props.returnKeyType ? this.props.returnKeyType : null}
                    onBlur={this.handleBlur}
                    onSubmitEditing={() => { this.props.onSubmitEditing ? this.props.onSubmitEditing() : null }}
                    blurOnSubmit={this.props.blurOnSubmit ? this.props.blurOnSubmit : true}
                    style={{ marginTop: 15, marginBottom: 3, height: 50, paddingLeft: 5, borderBottomWidth: 2, borderColor: this.state.isFocused ? Colors.tintColor : '#D3D3D3' }}
                />
                {this.state.errorMessage || this.props.errorMessage ? <Text style={styles.errorText}>{this.state.errorMessage ? this.state.errorMessage : this.props.errorMessage}</Text> : null}
            </View>

        );
    }
}

const styles = StyleSheet.create({
    errorText: {
        color: 'red',
        fontSize: 10
    }
})