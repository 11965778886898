import React from 'react';
import { StyleSheet, Text, Image, View, ActivityIndicator } from 'react-native';

import Colors from '../constants/Colors';

export default class DefaultLoading extends React.Component {
    render() {
        return (
            <View style={styles.loadingContainer}>
                <Image style={styles.gifLoading} source={require('../assets/images/loading.gif')}></Image>
                <Text style={[styles.loadingText, { color: this.props.color || Colors.text }]}>{this.props.title ? this.props.title : 'Carregando...'}</Text>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    loadingContainer: {
        marginTop: 0,
        width: '100%',
        justifyContent: "center",
        alignSelf: "center",
        alignContent: "center",
        marginBottom: 100
    },
    loadingText: {
        fontSize: 14,
        fontWeight: '600',
        color: Colors.text,
        justifyContent: "center",
        textAlign: "center",
        alignSelf: "center"
    },
    gifLoading: {
        width: 96,
        height: 96,
        alignSelf: 'center',
        marginBottom: 10,
        borderRadius: 10
    }
});