import React from 'react';
import { TouchableOpacity, StyleSheet, Text, View, Image } from 'react-native';
import { FontAwesome5 } from '@expo/vector-icons';
import {
	Placeholder,
	PlaceholderMedia,
	PlaceholderLine,
	Shine
} from "rn-placeholder";

import Colors from '../constants/Colors';

export default class DefaultCard extends React.Component {

	render() {
		return (
			<View style={style.cardWrapper}>
				<TouchableOpacity style={style.card} onPress={() => { this.props.onPress() }}>
					
				</TouchableOpacity>
			</View>

		);
	}
}

const style = StyleSheet.create({
	cardWrapper: {
		padding: 10
	},
	card: {
		flex: 1,
		width: '100%',
		padding: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		flexDirection: 'row',
		alignItems: 'flex-start',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		height: 120,
	},
});