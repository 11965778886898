import Colors from '../constants/Colors';
import { StatusBar, Platform } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';

export function getNavigationOptions(title, leftButton, color) {
  return {
    headerBackTitle: 'Voltar',
    title: title,
    headerStyle: {
      backgroundColor: color ? color : Colors.tintColor,
    },
    headerTintColor: '#fff',
    headerTitleStyle: {
      fontWeight: 'bold',
    },
    headerRight: () => leftButton ? leftButton : null
  }
}

export function toggleStatusBarStyle(instance, theme) {
  instance._navListener = instance.props.navigation.addListener('didFocus', () => {
    StatusBar.setBarStyle(theme);
    if(Platform.OS === 'android') {
      if(theme !== 'light-content') {
        StatusBar.setTranslucent(true);
        StatusBar.setBackgroundColor('transparent');
      } else {
        StatusBar.setTranslucent(false);
        StatusBar.setBackgroundColor(Colors.tintColor);
      }
    } 
  });
}