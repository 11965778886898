import * as React from 'react';
import { StyleSheet, Text, View, Image, TouchableOpacity, ActivityIndicator, Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';

import { FontAwesome5 } from '@expo/vector-icons';
import { showMessage } from "react-native-flash-message";
import DateTimePickerModal from "react-native-modal-datetime-picker";
import moment from 'moment';
import 'moment/locale/pt-br';

import { getNavigationOptions, toggleStatusBarStyle } from '../helper/NavigationHelper';
import SessionHelper from '../helper/SessionHelper';
import SATHelper from '../helper/SATHelper';

import Firestore from '../api/firebase/Firestore';
import Storage from '../api/firebase/Storage';

import * as firebase from 'firebase';

import Colors from '../constants/Colors';

import LoadingOverlay from '../components/LoadingOverlay';
import LocationPicker from '../components/Map/LocationPicker';
import DefaultButton from '../components/DefaultButton';
import TextArea from '../components/TextArea';
import GeographicHelper from '../helper/GeographicHelper';
import { TextInputMask } from 'react-native-masked-text';
import DefaultInput from '../components/DefaultInput';


export default class RegisterHoursScreen extends React.Component {
	state = {
		loading: true,
		loadingAttachment: false,
		technicianId: SessionHelper.getTechnician().id,
		userId: SessionHelper.getFirebaseAuth().uid,
		hourDescription: '',
		startTime: null,
		endTime: null,
		sat: null,
		isDatePickerVisible: false,
		datePickerField: '',
		coordinates: {
			lat: 0,
			lng: 0
		}
	}

	async componentDidMount() {
		const { navigation } = this.props;
		navigation.setParams({
			registerHours: this.registerHours
		});

		await this.setNavigationData(this.props.navigation.state.params);

		this.setState({ loading: false });
	}

	async setNavigationData(params) {
		this.setState({
			sat: params.sat,
		});
	}

	registerHours = async () => {
		if (!this.state.startTime) {
			showMessage({ icon: 'warning', message: "Hora de ínicio é obrigatória!", type: "warning" });
			return;
		}

		const timeDiff = this.state.startTime && this.state.endTime ? moment(this.state.endTime).diff(this.state.startTime, 'minutes') : null;

		if (this.state.startTime && this.state.endTime && timeDiff <= 1) {
			showMessage({ icon: 'warning', message: "Hora de ínicio e término não podem ser iguais!", type: "warning" });
			return;
		}

		await this.syncSat();

		if (this.state.sat.technicianHours && this.state.sat.technicianHours.length) {
			let valid = true;

			this.state.sat.technicianHours.forEach(hour => {
				if (hour.technician_id === this.state.technicianId) {
					if (this.state.startTime && this.state.endTime) {
						if ((moment(this.state.startTime).isBetween(moment(hour.startDate.toDate()), moment(hour.endDate ? hour.endDate.toDate() : hour.startDate.toDate())))
							|| (moment(this.state.endTime).isBetween(moment(hour.startDate.toDate()), moment(hour.endDate ? hour.endDate.toDate() : hour.startDate.toDate())))) {
							valid = false;
						}
					} else if (this.state.startTime && !this.state.endTime) {
						if ((moment(this.state.startTime).isBetween(moment(hour.startDate.toDate()), moment(hour.endDate ? hour.endDate.toDate() : hour.startDate.toDate())))) {
							valid = false;
						}
					}
				}
			});

			if (!valid) {
				showMessage({ icon: 'warning', message: "Você já apontou horas nesse intervalo de tempo", type: "warning" });
				return;
			}
		}

		this.setState({ loading: true });

		let data = [];

		if (this.state.sat.technicianHours) data = this.state.sat.technicianHours;

		data.push({
			startDate: this.state.startTime,
			endDate: this.state.endTime,
			description: this.state.hourDescription,
			minutesWorked: timeDiff,
			coordinates: new firebase.firestore.GeoPoint(this.state.coordinates.lat, this.state.coordinates.lng),
			technician_id: this.state.technicianId,
			user_id: this.state.userId
		});

		try {
			await Firestore.update({ technicianHours: data }, 'appointment', this.state.sat.id);

			if (this.state.endTime) {
				await SATHelper.historyIteration(this.state.sat.id, this.state.userId,
					`Técnico apontou ${timeDiff} minutos de trabalho, de ${moment(this.state.startTime).format('DD/MM/YYYY HH:mm')} à ${moment(this.state.endTime).format('DD/MM/YYYY HH:mm')}. ${this.state.hourDescription ? 'Descreveu como: "' + this.state.hourDescription + '"' : ''}`, new Date());
			} else {
				await SATHelper.historyIteration(this.state.sat.id, this.state.userId,
					`Técnico apontou inicio de horário de trabalho em ${moment(this.state.startTime).format('DD/MM/YYYY HH:mm')}. ${this.state.hourDescription ? 'Descreveu como: "' + this.state.hourDescription + '"' : ''}`, new Date());
			}

			await GeographicHelper.recordTechnicianGeodata([{ coords: { latitude: this.state.coordinates.lat, longitude: this.state.coordinates.lng }, speed: 0 }]);

			if (this.state.endTime) {
				showMessage({ icon: 'success', message: "Apontamento de horas realizado com sucesso", type: "success" });
			} else {
				showMessage({ icon: 'info', message: "Inicio de trabalho realizado com sucesso, lembre-se de apontar o final mais tarde!", type: "info", duration: 10000, hideOnPress: true });
			}

			this.props.navigation.goBack();
		} catch (e) {
			showMessage({ icon: 'danger', message: "Erro ao realizar apontamento :(", type: "danger" });

			this.setState({ loading: false });
		}

	}

	syncSat = async () => {
		const query = await Firestore.getDoc('appointment', this.state.sat.id);

		let sat = query.data();
		sat.id = query.id;

		this.setState({ sat });
	}

	static navigationOptions = ({ navigation }) => {
		const { params = {} } = navigation.state;

		return getNavigationOptions(`Apontar Horas ${navigation.state.params.sat.title.replace('Assistência ', '')}`,
			<View style={{ flexDirection: 'row' }}>
				<TouchableOpacity onPress={() => { params.registerHours() }}>
					<FontAwesome5 color={'white'} style={{ paddingHorizontal: 10 }} size={22} name={'check'} />
				</TouchableOpacity>
			</View>
		);
	};

	handleSelectDate(field, date) {
		let oldState = this.state;
		oldState[field] = date;
		oldState['isDatePickerVisible'] = false;

		this.setState(oldState);
	}

	renderHeader(icon, title) {
		return (
			<View style={styles.cardHeader}>
				<FontAwesome5 style={styles.cardHeaderIcon} light name={icon} />
				<Text style={styles.cardHeaderText}>{title}</Text>
			</View>
		);
	}

	renderDescriptionCard = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('comment', 'Descrição do Período')}
				<View style={[styles.card, { paddingHorizontal: 15 }]}>
					<TextArea numberOfLines={15} onChangeText={(value) => {
						this.setState({ hourDescription: value });
					}
					}
						placeholder={'Descreva aqui suas observações sobre este período de trabalho... (Opcional)'}
						style={{ height: 85, width: '100%', textAlignVertical: 'top' }}
					/>
				</View>
			</View>
		);
	}

	renderStartTime = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('play', 'Horário de ínicio')}

				<View style={[styles.card, { height: 140, justifyContent: 'center', paddingHorizontal: 15 }]}>
					{Platform.OS == 'web' ?
						<TextInputMask
							includeRawValueInChangeText={true}
							customTextInput={DefaultInput}
							customTextInputProps={{ keyboardType: 'number-pad', placeholder: 'DD/MM/AAAA HH:mm', returnKeyType: 'done', ref: (input) => { this.birthDateInput = input; } }}
							type={'datetime'}
							options={{
								format: 'DD/MM/YYYY HH:mm'
							}}
							returnKeyType={'done'}
							value={this.state.visibleStartTime}
							onChangeText={(text, raw) => { this.setState({ startTime: raw, visibleStartTime: text }); }}
						/>
						:
						<DefaultButton color={Colors.contrastColor} title={this.state.startTime ? moment(this.state.startTime).format('DD/MM - HH:mm') : "Horário de inicio"} icon={'clock'} onPress={() => { this.setState({ isDatePickerVisible: true, datePickerField: 'startTime' }) }} />
					}
				</View>
			</View>
		);
	}

	renderEndTime = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('stop', 'Horário de término')}
				<View style={[styles.card, { height: 140, justifyContent: 'center', paddingHorizontal: 15 }]}>
				{Platform.OS == 'web' ?
						<TextInputMask
							includeRawValueInChangeText={true}
							customTextInput={DefaultInput}
							customTextInputProps={{ keyboardType: 'number-pad', placeholder: 'DD/MM/AAAA HH:mm', returnKeyType: 'done', ref: (input) => { this.birthDateInput = input; } }}
							type={'datetime'}
							options={{
								format: 'DD/MM/YYYY HH:mm'
							}}
							returnKeyType={'done'}
							value={this.state.visibleEndTime}
							onChangeText={(text, raw) => { this.setState({ endTime: raw, visibleEndTime: text }); }}
						/>
						:
						<DefaultButton color={Colors.contrastColor} title={this.state.endTime ? moment(this.state.endTime).format('DD/MM - HH:mm') : "Horário de término"} icon={'clock'} onPress={() => { this.setState({ isDatePickerVisible: true, datePickerField: 'endTime' }) }} />
					}
				</View>
			</View>
		);
	}

	renderDatePicker = () => {
		return (
			<DateTimePickerModal
				isVisible={this.state.isDatePickerVisible}
				date={this.state[this.state.datePickerField] ? this.state[this.state.datePickerField] : new Date()}
				maximumDate={new Date()}
				minimumDate={this.state.startTime && this.state.datePickerField !== 'startTime' ? this.state.startTime : new Date('01/01/1971')}
				headerTextIOS="Aponte o horário"
				confirmTextIOS="Confirmar"
				cancelTextIOS="Fechar"
				mode="datetime"
				locale={'pt-br'}
				minuteInterval={1}
				isDarkModeEnabled={false}
				onConfirm={(timestamp) => { this.handleSelectDate(this.state.datePickerField, timestamp) }}
				onCancel={() => { this.setState({ isDatePickerVisible: false }) }}
			/>
		);
	}

	handleMapChange = async (data) => {
		this.setState({
			coordinates: { lat: data.lat, lng: data.lng }
		});
	}

	renderMap = () => {
		return (
			<View style={styles.cardWrapper}>
				{this.renderHeader('map', 'Local do apontamento')}
				<View style={[styles.card, { paddingHorizontal: 2, paddingVertical: 2 }]}>
					<LocationPicker
						callback={this.handleMapChange}
						height={Platform.OS == 'web' ? 400 : 200}
						width={'100%'}
						locationButton={true}
					/>
				</View>
			</View>
		);
	}

	renderContent = () => {
		return (
			<View style={styles.container}>
				<ScrollView>
					{this.renderDescriptionCard()}
					{this.renderStartTime()}
					{this.renderEndTime()}
					{this.renderDatePicker()}
					{this.renderMap()}
				</ScrollView>
			</View>

		);
	}

	render() {
		return this.state.loading ? <LoadingOverlay title={'Realizando operação...'} /> : this.renderContent();
	}

}


const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: Colors.backgroundColor
	},
	userInfo: {
		flexDirection: 'row',
		paddingVertical: 20,
		paddingHorizontal: 20,
	},
	cardWrapper: {
		padding: 10
	},
	card: {
		width: '100%',
		paddingVertical: 10,
		borderRadius: 5,
		backgroundColor: '#fff',
		shadowColor: "#000",
		shadowOffset: {
			width: 0,
			height: 1,
		},
		shadowOpacity: 0.22,
		shadowRadius: 2.22,
		elevation: 1,
		minHeight: 130,
	},
	imageWrapper: {
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: Colors.tintColor,
		marginTop: 10,
		height: 68,
		width: 68,
		borderRadius: 68,
	},
	image: {
		aspectRatio: 1 / 1,
		height: 64,
		width: 64,
		borderRadius: 64
	},
	technicianBubbleText: {
		textAlign: 'center',
		paddingVertical: 8,
		fontSize: 12,
		color: Colors.text,
		fontWeight: "500",
		paddingHorizontal: 3
	},
	cardHeader: {
		flexDirection: 'row',
		width: '100%',
		marginBottom: 5,
		padding: 5,
		justifyContent: 'flex-start',
		textAlignVertical: 'center'
	},
	cardHeaderText: {
		fontWeight: 'bold',
		fontSize: 16,
		color: Colors.tintColor,
		marginLeft: 5
	},
	cardHeaderIcon: {
		fontSize: 18,
		color: Colors.tintColor,
		marginRight: 5
	},
	floatingButton: {
		position: 'absolute',
		bottom: 10,
		justifyContent: 'center',
		alignSelf: 'center',
		width: '90%'
	},
});
